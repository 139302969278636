import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as maskers from 'utils/maskers';

import './AddressBox.scss';

export default class AddressBox extends Component {
  static propTypes = {
    city: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    complement: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    neighbourhood: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    receiver: PropTypes.string.isRequired,
    handleChooseClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    showEditAddress: PropTypes.bool.isRequired,
  };

  render() {
    const {
      receiver,
      address,
      neighbourhood,
      city,
      state,
      reference,
      number,
      complement,
      zipcode,
      handleChooseClick,
      handleEditClick,
      showEditAddress,
    } = this.props;

    return (
      <div className="AddressBox">
        <div className="AddressBox-leftContainer">
          <div className="AddressBox-title">
            {receiver}
          </div>
          <div className="AddressBox-address">
            {maskers.fullAddress(address, neighbourhood, city, state, number, complement, reference)}
          </div>
          <div className="AddressBox-zipcode">
            CEP: {maskers.zipcode(zipcode)}
          </div>
          {showEditAddress &&
            <a
              onClick={handleEditClick}
              href="#"
              className="AddressBox-edit"
              data-ga='{"category": "Endereco", "action": "Endereco cadastrado", "label": "Editar"}'
            > Editar
            </a>
          }
        </div>
        <div className="AddressBox-rightContainer">
          <button className="AddressBox-continue"
            onClick={handleChooseClick}
            data-ga='{"category": "Endereco", "action": "Endereco cadastrado", "label": "Continuar"}'
          >Entregar aqui</button>
        </div>
      </div>
    );
  }
}
