import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';

import BasketAddress from 'components/Basket/BasketAddress/BasketAddress';
import * as shipmentActions from 'reducers/shipment';
import * as basketActions from 'reducers/basket';
import * as validators from 'utils/validators';
import * as cleaners from 'utils/value-cleaners';

const { object, func, bool, string } = PropTypes;
const mapStateToProps = ({ basket, shipment }) => ({
  ...basket,
  ...shipment,
  isBasketRequesting: basket.isRequesting,
});

@reduxForm({
  form: 'zipcode',
  fields: ['zipcode'],
})
@connect(mapStateToProps, { ...shipmentActions, ...basketActions, initialize })
export default class BasketShipmentContainer extends Component {
  static propTypes = {
    zipCode: string,
    fields: object.isRequired,
    initialize: func.isRequired,
    basket: object.isRequired,
    shipment: object.isRequired,
    isBasketRequesting: bool.isRequired,
    handleSubmit: func.isRequired,
    fetchShipment: func.isRequired,
    basketReady: func.isRequired,
    basketCheckoutProgress: func.isRequired,
    basketViewCart: func.isRequired,
    basketShippingCalcImpression: func.isRequired,
    triggerClickEventGA4: func.isRequired, 
  };

  state = {
    showForm: true,
    focus: false,
  };

  componentDidMount() {
    const {
      initialize: initializeForm,
      shipment: { zipcode },
      fields,
      basketReady,
      basketCheckoutProgress,
      basketViewCart,
      basketShippingCalcImpression,
    } = this.props;

    if (zipcode) {
      this.setState({ showForm: false });
      initializeForm('zipcode', { zipcode }, Object.keys(fields));
      basketShippingCalcImpression();
    }
    basketReady();
    basketCheckoutProgress();
    basketViewCart();
  }

  render() {
    const {
      fields: { zipcode },
      handleSubmit,
      shipment,
      isBasketRequesting,
      basket: {
        totals: { shipment: shipmentCost },
      },
      fetchShipment,
      fetchBasket,
      triggerClickEventGA4
    } = this.props;
    const zipcodeValue = zipcode.value && cleaners.zipcode(zipcode.value);

    return (
      <div className="BasketShipment">
        <BasketAddress
          {...shipment}
          shipmentCost={shipmentCost}
          zipcodeField={zipcode}
          isRequesting={isBasketRequesting}
          showForm={this.state.showForm}
          focus={this.state.focus}
          handleAddClick={() => triggerClickEventGA4('nao-sei-o-cep') }
          handleSubmit={handleSubmit(() => {
            if (validators.zipcode(zipcodeValue)) {
              // Fetch only if user input is different from previous
              if (shipment.zipcode !== zipcodeValue) {
                fetchShipment(zipcodeValue).then(() => {
                  this.setState({
                    showForm: false,
                    shipment: { shipment: zipcodeValue },
                  });
                  fetchBasket();
                });
              } else {
                this.setState({ showForm: false });
              }
            }
          })}
          handleModifyClick={(e) => {
            e.preventDefault();
            triggerClickEventGA4('alterar-cep');
            this.setState({
              showForm: true,
              focus: true,
            });
          }}
        />
      </div>
    );
  }
}
