import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookies from 'browser-cookies';

import * as routeNames from 'constants/route-names';
import * as customerMessages from 'constants/customer-messages';
import { FACEBOOK, GOOGLE } from 'constants/social-login';
import { stwuCookieName } from 'constants/stewie-constants';

import { getNextUrl, getOriginUrl } from 'utils/login';
import { redirectToNextUrl } from 'utils/redirect';

import { socialProfile } from 'api/models/customer-models';

import {
  showLoading,
  hideLoading,
} from 'reducers/loading';
import * as signupAvailableActions from 'reducers/signup-available';
import * as socialLoginActions from 'reducers/social-login';
import * as failActions from 'reducers/fail';

import SocialLoginBox from 'components/Login/SocialLoginBox/SocialLoginBox';
import { validateCaptcha } from 'components/Form/Captcha/Captcha';
import {adsCollectorLogin} from 'utils/magalu-ads-collector/magalu-ads-collector';

const { object, func, bool } = PropTypes;

const mapStateToProps = ({
  channel,
}) => ({
  configs: channel.configs,
});

const mapDispatchToActions = {
  ...socialLoginActions,
  ...signupAvailableActions,
  ...failActions,
  showLoading,
  hideLoading,
};

export class SocialLoginBoxContainer extends Component {
  static propTypes = {
    requestProfile: func.isRequired,
    receiveProfile: func.isRequired,
    fetchSocialLogin: func.isRequired,
    fetchLoginAvailableFromSocial: func.isRequired,
    postSocialLogin: func.isRequired,
    fail: func.isRequired,
    location: object.isRequired,
    configs: object.isRequired,
    showLoading: func.isRequired,
    hideLoading: func.isRequired,
    isSignup: bool
  };

  static defaultProps = {
    isSignup: false
  }

  static contextTypes = {
    router: object.isRequired
  };

  socialCallback = source => {
    return profile => {
      const {
        receiveProfile,
        fetchSocialLogin,
        fetchLoginAvailableFromSocial,
        postSocialLogin,
        fail,
        location: { query }
      } = this.props;
      const { router } = this.context;
      const { origin, next } = query;
      const cookieStwu = cookies.get(stwuCookieName);

      this.props.showLoading('SocialLogin');

      if (profile) {
        const nextUrl = next || getNextUrl(location.href);
        const originUrl = origin || getOriginUrl(nextUrl);

        const goToNextUrl = () => redirectToNextUrl(router.replace, {
          next: nextUrl,
          origin: originUrl,
        });
        const goToSignup = () => redirectToNextUrl(router.push, { next: routeNames.signup, nextQuery: query });
        const profileWithSource = Object.assign({}, socialProfile(source, profile), { source });
        const { socialClientId, accessToken, email } = profileWithSource;
        receiveProfile(profileWithSource);

        return fetchSocialLogin(source, accessToken, socialClientId, email)
          .then(async (res) => {
            await adsCollectorLogin(res.clientId, cookieStwu);
            this.props.hideLoading('SocialLogin');
            goToNextUrl();
          })
          .catch(err => {
            this.props.hideLoading('SocialLogin');

            if (err.status === 404) {
              validateCaptcha('pre_signup_email')
                .then((captchaToken) => fetchLoginAvailableFromSocial(captchaToken))
                .then(goToSignup)
                .catch(({ errored }) => {
                  if (errored) {
                    return fail(
                      customerMessages.socialLoginFail,
                      status,
                      'Failed when trying to fetch login after social login attempt'
                    );
                  }

                  return postSocialLogin()
                    .then(goToNextUrl)
                    .catch(() => {});
                });
            }
          });
      }

      this.props.hideLoading('SocialLogin');
    };
  };

  render() {
    return (
      <SocialLoginBox
        FBAppId={this.props.configs.fb_app_id}
        onClick={this.props.requestProfile}
        FBCallback={this.socialCallback(FACEBOOK)}
        GoogleCallback={this.socialCallback(GOOGLE)}
        googleClientId={this.props.configs.google_client_id}
        showLoading={this.props.showLoading}
        hideLoading={this.props.hideLoading}
        isSignup={this.props.isSignup}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SocialLoginBoxContainer);
