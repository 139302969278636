import MagaluService from '@magalu/webzord';

const requestWebzord = ({ serviceName, callbackId, serviceArgs, callback }) =>
  MagaluService.request({
    serviceArgs,
    serviceName,
    callbackId,
    callback,
  });

export default requestWebzord;
