import * as customLabes from 'constants/signin-labels';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as validators from 'utils/validators';

import { REDIRECT_COMPANY_URL } from 'constants/channels';
import { nationalResetPasswordIdentificationUrl } from 'constants/external-urls';
import { BUTTON_LOGIN_ID } from 'constants/ids';

import ButtonShowPassword from 'components/Form/ButtonShowPassword/ButtonShowPassword';
import FormGroup from 'components/Form/FormGroup/FormGroup';
import { getChannelConfig } from 'utils/session';

import './LoginBox.scss';

const { object } = PropTypes;

const enablePJRedirect = getChannelConfig(
  'enable_pj_customer_redirect_to_magalu_empresas'
) ?? false;

const mapStateToProps = ({
  channel,
}) => ({
  configs: channel.configs,
});

@connect(mapStateToProps, undefined)
export default class LoginBox extends Component {
  static propTypes = {
    configs: object
  };

  
  constructor(props) {
    super(props);

    if (!!props.configs.type_authentication && validators.isAuthenticationByCpf(props.configs.type_authentication)) {
      this.state = { 'authentication_label': customLabes.labelCpf };
    } else if (!!props.configs.type_authentication && validators.isAuthenticationByEmail(props.configs.type_authentication)) {
      this.state = { 'authentication_label': customLabes.labelEmail };
    } else if (!enablePJRedirect) {
      this.state = { 'authentication_label': customLabes.labelEmailCpfCnpj };
    } else {
      this.state = { 'authentication_label': customLabes.labelEmailCpfEnabledRedirect };
    }
  }

  handleChange = (event) => {
    this.props.loginField.onChange(event.target.value.toLowerCase());
  };

  handleForgotPasswordNationalClick = (e) => {
    e.preventDefault();

    const { value: loginField } = this.props?.loginField;
    const searchParams = new URLSearchParams();
    if (loginField) {
      searchParams.append('from_user', Buffer.from(loginField).toString('base64'));
    }
    window.location = `${nationalResetPasswordIdentificationUrl}/?${searchParams.toString()}`;

  };

  render() {
    const {
      loginField,
      passwordField,
      handleSubmit,
      handleShowPasswordClick,
      showPassword,
      errorMessage: loginErrorMessage,
      isRequesting,
      captcha,
    } = this.props;

    const magaluEmpresasUrl = getChannelConfig(REDIRECT_COMPANY_URL);
    const formId = 'LoginBox-form';

    return (
      <div className="LoginBox">
        <div className="LoginBox-title">Já sou cliente</div>
        <form
          id={formId}
          className="LoginBox-form"
          onSubmit={handleSubmit}
          method="post"
        >
          <FormGroup
            autocomplete="username"
            labelText={this.state.authentication_label}
            field={loginField}
            hideError={!!loginErrorMessage || isRequesting}
            handleChange={this.handleChange}
            inputSize="long"
          />
          <FormGroup
            autocomplete="current-password"
            labelText="Senha"
            field={Object.assign({}, passwordField, {
              type: showPassword && 'text' || 'password'
            })}
            hideError={!!loginErrorMessage || isRequesting}
            hideSuccess
            inputSize="long"
          >
            <ButtonShowPassword
              field={showPassword}
              handleClick={() => handleShowPasswordClick('showPassword')}
            />
          </FormGroup>
          { loginErrorMessage && (
            <div className="LoginBox-form-error--unique">
              {loginErrorMessage}
            </div>
          )}
          {captcha}
          <button
            className="LoginBox-form-continue"
            id={BUTTON_LOGIN_ID}
          >
            Continuar
          </button>
        </form>
        <div className="LoginBox-help">
          <a
            href="#"
            onClick={this.handleForgotPasswordNationalClick}
            data-ga='{"category": "Login", "action": "Esqueci minha senha", "label": "Selecionou"}'
          >
              Esqueci minha senha
          </a>
        </div>
        {enablePJRedirect && (
          <div className="LoginBox-help">
            <strong className="LoginBox-strong">Possui CNPJ? </strong>Faça seu login pelo {''}
            <a
              href={magaluEmpresasUrl}
            >
            Portal Magalu Empresas
            </a>
          </div>
        )} 
      </div>
    );
  }
}

LoginBox.propTypes = {
  loginField: PropTypes.object.isRequired,
  passwordField: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  handleShowPasswordClick: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  captcha: PropTypes.node,
};
