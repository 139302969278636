import {
  zipcode as zipcodeCleaner,
  onlyNumbers as onlyNumbersCleaner,
} from 'utils/value-cleaners';

import { telephoneData } from 'api/models/customer-models';


export const simpleAddress = ({
  area: neighbourhood = '',
  address: addressString = '',
  city,
  zip_code: zipcode,
  state,
  customer_fullname
}) => ({
  neighbourhood,
  address: addressString,
  city,
  zipcode,
  state,
  receiver: customer_fullname
});

export const customerAddress = ({
  city,
  id,
  isDefault,
  addressUuid,
  number,
  complement = '',
  state,
  reference = '',
  address,
  village: neighbourhood,
  phone,
  zipCode: zipcodeV1,
  zip_code: zipcodeP52,
  areaCode,
  name: receiver
}) => {
  const telephone = `${areaCode}${phone}`;

  return {
    city,
    reference,
    id,
    isDefault,
    addressUuid,
    number,
    complement,
    state,
    address,
    neighbourhood,
    telephone,
    zipcode: zipcodeCleaner(zipcodeV1 || zipcodeP52),
    receiver
  };
};

export const addressData = ({
  zipcode,
  address,
  number,
  complement,
  neighbourhood,
  city,
  state,
  reference,
  telephone,
  receiver,
  isDefault,
  addressUuid = ''
}) => {
  const phone = telephoneData(onlyNumbersCleaner(telephone));
  const data = {
    zip_code: zipcodeCleaner(zipcode),
    address,
    number,
    complement,
    village: neighbourhood,
    city,
    state,
    reference,
    area_code: phone.areaCode,
    phone: phone.number,
    name: receiver,
    is_default: isDefault
  };

  if (addressUuid) {
    return Object.assign({}, data, { address_uuid: addressUuid });
  }

  return data;
};
