import { purchaseCloudRetailLayer, purchaseLayer } from 'utils/data-layer/purchaseLayer';
import { addPaymentInfoEvent } from 'utils/data-layer/addPaymentInfo';
import { pageviewLayer } from 'utils/data-layer/pageviewLayer';
import { purchaseLayerGA4 } from './purchaseLayerGA4';
import { addPaymentInfoEventGA4 } from './addPaymentInfoGA4';
import { isArray } from 'lodash';

const PURCHASE_ORDER_STORAGE_DATA = 'purchaseOrderStorageData';

function orderPageview(_action, _stateBefore, stateAfter) {

  const orderNumber = stateAfter?.order?.order?.id;
  const purchaseStorage = window?.sessionStorage?.getItem(PURCHASE_ORDER_STORAGE_DATA) || [];
  let purchaseLayerGA4Onetime = null;

  if (!purchaseStorage.includes(orderNumber) && isArray(purchaseStorage) && purchaseStorage.length < 5) {
    purchaseLayerGA4Onetime = purchaseLayerGA4(stateAfter);
    purchaseStorage.push(orderNumber);
    window?.sessionStorage?.setItem(PURCHASE_ORDER_STORAGE_DATA, purchaseStorage);
  }

  return [
    purchaseLayer(stateAfter),
    pageviewLayer(stateAfter, '5'),
    purchaseCloudRetailLayer(stateAfter),
    purchaseLayerGA4Onetime
  ];
}

function orderAddPaymentInfo(_action, _stateBefore, stateAfter) {
  return addPaymentInfoEvent(
    stateAfter.basket.basket.totals,
    stateAfter.order.paymentData,
    stateAfter.basket.basket.id
  );
}

function orderAddPaymentInfoGA4(action, _stateBefore, stateAfter) {
  return addPaymentInfoEventGA4(action, stateAfter);
}


function copyPixClick() {
  const layer = {};

  layer.event = 'clickEvent';
  layer.eventCategory = 'confirmacao-pedido:cliente';
  layer.eventAction = 'click';
  layer.eventLabel = 'copiar-codigo-pix';
  layer.eventNonInteraction = '0';
  layer.hitTimestamp = `${Date.now()}`;

  return layer;
}

export {
  orderAddPaymentInfo,
  orderAddPaymentInfoGA4,
  orderPageview,
  copyPixClick
};
