import React from 'react';

import Modal from 'components/Modal/Modal';
import ShipmentInAccordance from 'icons/shipment-in-accordance';

import './InternationalTaxContingencyModal.scss';
import { addToDatalayer } from 'utils/gtm';

const ModalContent = () => {
  return (
    <div className="InternationalTaxContingencyModal">
      <div className="InternationalTaxContingencyModal-content">
        <ShipmentInAccordance className="InternationalTaxContingencyModal-icon" />

        <p className="font-sm-regular">
          Com o programa <span className="font-sm-medium">Remessa Conforme</span>, para
          compras abaixo de 50 dólares, você paga apenas o ICMS, sem impostos adicionais
          de importação. Não há taxas extras na alfândega!
        </p>
      </div>

      <div className="InternationalTaxContingencyModal-content-column">
        <p className="font-sm-regular">
          • Para compras <span className="font-sm-medium">até 50 dólares</span>, você paga 20%
          de taxa de importação e o ICMS*, sem taxas extras na alfândega.
        </p>

        <p className="font-sm-regular">
          • Para compras <span className="font-sm-medium">acima de 50,01 dólares</span>, a taxa
          de importação é de 60%, com um desconto de 20 dólares, além do ICMS*. E não há
          taxas extras na alfândega.
        </p>
        <p>
          <span className="font-sm-medium">*ICMS: </span>
          <span className="font-sm-regular">
            Imposto estadual sobre a circulação de produtos e serviços no Brasil. Imposto
            estadual sobre a circulação de produtos e serviços no Brasil, definido em 17%
            independentemente do valor da compra.
          </span>
        </p>
      </div>
    </div>
  );
};

const ModalFooter = ({ toggle }) => {
  const handleClick = () => {
    const layer = {
      event: 'clickEvent',
      eventCategory: 'produto:compra-internacional',
      eventAction: 'click',
      eventLabel: 'entendi',
      eventNonInteraction: '0',
      hitTimestamp: `${new Date().getTime()}`,
    };

    addToDatalayer(layer, window.dataLayer || []);
    return toggle();
  };

  return (
    <button
      className="BtnLarge InternationalTaxContingencyModal-btn"
      onClick={handleClick}>
      Entendi
    </button>
  );
};

const InternationalTaxContingencyModal = ({ isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Compra Internacional"
      content={ModalContent}
      footer={ModalFooter}
      toggle={toggle}
      mobileIsDrawer
    />
  );
};

export default InternationalTaxContingencyModal;
