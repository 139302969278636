import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './Timer.scss';

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: null,
      minutes: null,
    };
  }

  componentDidMount() {
    const { expiresAt } = this.props;
    const duration = this.formatTimestampToDuration(expiresAt);
    this.setState({
      seconds: duration.seconds(),
      minutes: duration.minutes(),
    }, this.tick.bind(this));
  }

  componentDidUpdate() {
    if (this.state.seconds === 0 && this.state.minutes === 0) {
      clearInterval(this.timerID);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const { countdownInterval } = this.props;
    this.timerID = setInterval(
      () => this.setState((_, props) => {
        const duration = this.formatTimestampToDuration(props.expiresAt);
        return {
          seconds: duration.seconds(),
          minutes: duration.minutes(),
        };
      }),
      countdownInterval,
    );
  }

  formatTimestampToDuration(timestamp) {
    return moment.duration(moment.unix(timestamp).diff(moment()));
  }

  async handleResendCode() {
    const { onResendSubmit } = this.props;
    await onResendSubmit();
    this.tick().bind(this);
  }

  render() {
    return (
      <div className="Timer">
        <button
          type="button"
          onClick={() => this.handleResendCode()}
          className="Timer-button"
          disabled={this.state.minutes > 0 || this.state.seconds > 0}
        >
          Reenviar código
        </button>
        {(this.state.minutes > 0 || this.state.seconds > 0) && (
          <span className="Timer-formattedTime">
            {' '}em{' '}
            {String(this.state.minutes).padStart(2, '0')}:{String(this.state.seconds).padStart(2, '0')}
          </span>
        )}
      </div>
    );
  }
}

Timer.propTypes = {
  countdownInterval: PropTypes.number,
  onResendSubmit: PropTypes.func.isRequired,
  expiresAt: PropTypes.number.isRequired,
};

Timer.defaultProps = {
  countdownInterval: 1000,
};
