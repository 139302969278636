import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';

import * as cardFlags from 'constants/card-flags';
import * as paymentTypes from 'constants/payment-types';

import CardIcon from 'icons/payment/icon-credit-card.svg';
import VirtualDebitCardIcon from 'icons/payment/icon-caixa.svg';
import IconPix from 'icons/payment/icon-pix.svg';
import BankSlipIcon from 'icons/payment/icon-bank-slip.svg';
import ValeCompraIcon from 'icons/payment/icon-vale-compra.svg';
import SecurePurchaseIcon from 'icons/payment/icon-secure-purchase.svg';

import { scrollToElement } from 'utils/scroll';
import * as maskers from 'utils/maskers';
import * as validators from 'utils/validators';
import { onlyNumbers } from 'utils/value-cleaners';
import {
  getLabelValeCompraOption,
  getTypeInputValeCompra,
  isOnlyValeCompra,
} from 'utils/review-order';
import { storesHaveSameDayPickup, hasSameDayDelivery } from 'utils/packages';

import { bankSlipData, pixData, valeCompraData } from 'api/models/payment-models';

import * as orderActions from 'reducers/order';
import * as installmentsActions from 'reducers/installments';
import * as basketActions from 'reducers/basket';

import PaymentBoxLine from 'components/Payment/PaymentBoxLine/PaymentBoxLine';
import BankSlipForm from 'components/Payment/Forms/BankSlipForm/BankSlipForm';
import PixForm from 'components/Payment/Forms/PixForm/PixForm';
import ValeCompraForm from 'components/Payment/Forms/ValeCompraForm/ValeCompraForm';
import Responsive from 'components/Responsive';
import SavedCardLine from 'components/Payment/PaymentBoxLine/SavedCardLine';
import { PaymentFailModal } from 'components/Payment/PaymentFail/PaymentModalFail';

import NewCardFormContainer from 'containers/NewCardFormContainer';
import SavedCardFormContainer from 'containers/SavedCardFormContainer';
import VirtualDebitEloFormContainer from 'containers/VirtualDebitEloFormContainer';

import 'components/Payment/Forms/PaymentForms/PaymentForms.scss';
import { NATIONAL_CURRENCY } from 'constants/currency';
import PaymentWithoutInternationalTax from 'components/Payment/PaymentWithoutInternationalTax/PaymentWithoutInternationalTax';
import InternationalPurchaseValidationModal from 'components/InternationalPurchaseValidationModal/InternationalPurchaseValidationModal';
import withModal from 'hocs/withModal/withModal';
import { findInternationalProductCurrency } from 'utils/currency';

export class PaymentBoxContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    handleToggle: PropTypes.func,
    currentType: PropTypes.string,
    deliveries: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    cardList: PropTypes.array.isRequired,
    installmentsPerType: PropTypes.object.isRequired,
    changePaymentType: PropTypes.func.isRequired,
    autoChangePaymentType: PropTypes.func.isRequired,
    fetchInstallments: PropTypes.func.isRequired,
    resetInstallmentsSelect: PropTypes.func.isRequired,
    postPlaceOrder: PropTypes.func.isRequired,
    shippingPackages: PropTypes.object.isRequired,
    redirectToAddress: PropTypes.func.isRequired,
    redirectToBasket: PropTypes.func.isRequired,
    postCallMe: PropTypes.func.isRequired,
    availableCredits: PropTypes.array.isRequired,
    useCredit: PropTypes.bool.isRequired,
    updateCredit: PropTypes.func.isRequired,
    fetchPackages: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    campaignCode: PropTypes.string,
    itemsSku: PropTypes.array,
    showBankSlip: PropTypes.bool.isRequired,
    showNewCreditCard: PropTypes.bool.isRequired,
    showVirtualDebitElo: PropTypes.bool,
    showSavedCreditCards: PropTypes.bool.isRequired,
    showValeCompra: PropTypes.bool.isRequired,
    showPix: PropTypes.bool.isRequired,
    enableEncryptedCard: PropTypes.bool.isRequired,
    allowedBins: PropTypes.array,
    blackListedSku: PropTypes.array,
    enablePhoneSales: PropTypes.bool.isRequired,
    credits: PropTypes.shape({
      totalAmount: PropTypes.string,
      totalAvailableAmount: PropTypes.string,
      totalRemainingAmount: PropTypes.string,
      remainingCartAmount: PropTypes.string,
    }),
    totals: PropTypes.shape({
      remainingCreditAmount: PropTypes.string,
      totalAmount: PropTypes.string,
      checkoutAmount: PropTypes.string,
      totalCheckoutAmount: PropTypes.string,
      remainingAmount: PropTypes.string,
    }),
    tmxSessionID: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const labelValeCompraOption = getLabelValeCompraOption(props);
    const {
      totals,
      shippingPackages: { hasMarketplaceItems },
    } = props;

    const onlyValeCompra = isOnlyValeCompra(totals.remainingAmount, hasMarketplaceItems);

    this.state = {
      isPaymentFailOpen: false,
      isCalcTaxesFailOpen: false,
      isRestrictedDocOpen: false,
      success: false,
      labelValeCompraOption,
      isPaymentModalOpen: !props.isMobile,
      onlyValeCompra,
      skuList: [],
      isLocked: '',
    };
  }

  componentWillMount() {
    return (
      this.changePaymentTypeToValeCompra() ||
      this.changePaymentTypeToDefaultCard() ||
      this.changePaymentTypeToNewCard()
    );
  }

  componentWillReceiveProps(nextProps) {
    this.getInstallments(nextProps);
    this.updateLabelValeCompra(nextProps);
    if (!!nextProps.itemsSku && !!nextProps.itemsSku.length) {
      this.setState({ skuList: nextProps.itemsSku });
    }
  }

  hasInternationalProduct = () => {
    const { deliveries } = this.props;
    const packages = deliveries?.packages;

    const currency = findInternationalProductCurrency(packages);
    return !!currency;
  };

  // Use this method if you need to get the total amount of different
  // payment methods like BankSlip
  getOtherPaymentAmount = (type) => {
    const { installmentsPerType } = this.props;
    const current = installmentsPerType[type];

    return (current && current.length && current[0].totalAmount) || '';
  };

  getBankSlipExpiration = () => {
    const { installmentsPerType } = this.props;
    const current = installmentsPerType[paymentTypes.bankSlip];

    return (current && current.length && current[0].expirationDate) || '';
  };

  getInstallments = (nextProps) => {
    if (nextProps.currentType !== this.props.currentType) {
      return this.fetchNonNewCardInstallments(nextProps);
    }

    if (
      !validators.isValidVirtualDebitEloCardNumber(
        onlyNumbers(nextProps.form.newCard.number.value)
      )
    ) {
      return this.fetchNewCardInstallments(nextProps);
    }
  };

  updateLabelValeCompra = (nextProps) => {
    if (
      this.props.useCredit !== nextProps.useCredit ||
      this.props.credits.totalAmount !== nextProps.credits.totalAmount
    ) {
      const labelValeCompraOption = getLabelValeCompraOption(nextProps);
      this.setState({
        labelValeCompraOption,
      });
    }
  };

  // new card form
  fetchNewCardInstallments = ({ form = {}, fetchInstallments }) => {
    const { newCard: { flag = {} } = {} } = form;
    const { newCard: { number = {} } = {} } = form;

    if (
      validators.isValidCardNumber(onlyNumbers(number.value)) &&
      flag &&
      flag.value &&
      flag.value !== this.props.form.newCard.flag.value
    ) {
      return fetchInstallments(flag.value);
    }
  };

  // other forms
  async fetchNonNewCardInstallments(nextProps) {
    const { currentType, cardList, fetchInstallments, updateCredit, useCredit } =
      nextProps;
    if (currentType && useCredit && this.state.onlyValeCompra) {
      await updateCredit(false);
    }

    if (currentType === paymentTypes.bankSlip || currentType === paymentTypes.pix) {
      return fetchInstallments(currentType);
    } else if (currentType !== paymentTypes.newCard) {
      const currentCard = cardList.find((card) => card.id === currentType) || {};
      return currentCard.flag && fetchInstallments(currentCard.flag);
    }
  }

  changePaymentTypeToDefaultCard = () => {
    const { currentType, autoChangePaymentType, cardList } = this.props;
    if (!currentType || currentType === paymentTypes.newCard) {
      const defaultCard = cardList.find((card) => !!card.isDefault);

      if (defaultCard) {
        autoChangePaymentType(defaultCard.id);
        return true;
      }
    }
    return false;
  };

  // Vale Compra form
  changePaymentTypeToValeCompra = () => {
    const { useCredit, currentType, changePaymentType } = this.props;
    if (useCredit) {
      if (currentType === paymentTypes.newCard) {
        changePaymentType('');
      }
      return true;
    }

    return false;
  };

  changePaymentTypeToNewCard = () => {
    this.props.autoChangePaymentType(paymentTypes.newCard);
  };

  async updateCreditAndFetchInstallmensts(checked, props) {
    await this.props.updateCredit(checked);

    this.state.onlyValeCompra = isOnlyValeCompra(
      this.props.totals.remainingAmount,
      this.props.shippingPackages.hasMarketplaceItems
    );

    if (this.state.onlyValeCompra) {
      this.props.changePaymentType('');
    } else if (this.props.currentType === paymentTypes.newCard) {
      if (
        validators.isValidCardNumber(onlyNumbers(props.form.newCard.number.value)) &&
        props.form.newCard.flag &&
        props.form.newCard.flag.value
      ) {
        return props
          .fetchInstallments(props.form.newCard.flag.value)
          .then(() => props.resetInstallmentsSelect('newCard'));
      }
    } else {
      this.fetchNonNewCardInstallments(props).then(() =>
        this.props.resetInstallmentsSelect('savedCard')
      );
    }
  }

  handleTypeChangeClick = (e) => {
    if (e.target.id === paymentTypes.valeCompra) {
      this.updateCreditAndFetchInstallmensts(e.target.checked, this.props);
    } else {
      if (e.target.value === paymentTypes.virtualDebitElo) {
        this.props.fetchInstallmentsVirtualDebitElo(e.target.value);
      } else if (e.target.id !== paymentTypes.newCard) {
        this.fetchNonNewCardInstallments(this.props);
      }

      this.props.changePaymentType(e.target.value);
    }

    maskers.handleLockBackgroundScroll('inherit');
    scrollToElement(e.target.id, { offset: -20 });
  };

  openPaymentFailModal = () => {
    if (this.props.isMobile) {
      this.setState({ isPaymentModalOpen: false });
    }
    this.setState({
      isPaymentFailOpen: true,
      isLocked: 'hidden',
    });
    maskers.handleLockBackgroundScroll(this.state.isLocked);
  };

  openCalcTaxesFailModal = () => {
    this.setState({
      isCalcTaxesFailOpen: true,
      isLocked: 'hidden',
    });
    maskers.handleLockBackgroundScroll(this.state.isLocked);
  };

  openRestrictedDocModal = () => {
    this.setState({
      isRestrictedDocOpen: true,
      isLocked: 'hidden',
    });
    maskers.handleLockBackgroundScroll(this.state.isLocked);
  };

  render() {
    const {
      currentType = '',
      cardList,
      postPlaceOrder,
      shippingPackages: { hasMarketplaceItems },
      credits,
      redirectToAddress,
      redirectToBasket,
      postCallMe,
      availableCredits,
      useCredit,
      isMobile,
      showSavedCreditCards,
      showNewCreditCard,
      showVirtualDebitElo,
      totals,
      shippingPackages,
      showBankSlip,
      showValeCompra,
      showPix,
      allowedBins,
      blackListedSku,
      enablePhoneSales,
      enableEncryptedCard,
      basket: { products },
      tmxSessionID,
      isOpen,
      handleToggle,
    } = this.props;

    const { labelValeCompraOption, isPaymentFailOpen, isCalcTaxesFailOpen, isRestrictedDocOpen, success } = this.state;

    const hideBankSlip = maskers.verifySpecialSkus(blackListedSku, this.state.skuList);

    const handleRedirectToBasket = () => {
      maskers.handleLockBackgroundScroll('inherit');
      redirectToBasket();
    };

    const handlePlaceOrderError = (error) => {
      if (error.status === 402) {
        this.openPaymentFailModal();
      } else if (error.status === 409 && error.errorCode === 'order_without_calculated_taxes') {
        this.openCalcTaxesFailModal();
      } else if (error.status === 409 && error.errorCode === 'customer_with_restricted_cpf') {
        this.openRestrictedDocModal();
      }
    };

    const handleSubmitPlaceOrder = async (event, paymentType, paymentData) => {
      event.preventDefault();

      try {
        await postPlaceOrder(
          {
            ...paymentData,
            items: products,
            customer_risk_analysis_id: tmxSessionID,
          },
          paymentType,
          redirectToAddress,
          redirectToBasket
        );
      } catch (error) {
        handlePlaceOrderError(error);
      }
    };

    return (
      <div>
        <PaymentFailModal
          handleRetryClick={(changeType) => {
            this.setState({ isPaymentFailOpen: false });
            this.handleTypeChangeClick(changeType);
          }}
          isOpen={isPaymentFailOpen}
          handleButtonClick={() => {
            postCallMe()
              .then(() => this.setState({ success: true }))
              .catch(() => {});
          }}
          hasMarketplaceItems={hasMarketplaceItems}
          success={success}
          handleRequestClose={() =>
            this.setState({
              isPaymentFailOpen: false,
              success: false,
            })
          }
          enablePhoneSales={enablePhoneSales}
        />

        <PaymentWithoutInternationalTax isOpen={isCalcTaxesFailOpen} toggle={handleRedirectToBasket} />

        <InternationalPurchaseValidationModal isOpen={isRestrictedDocOpen} toggle={handleRedirectToBasket} />

        <ul className="PaymentBox">
          {showValeCompra && !!availableCredits.length && (
            <PaymentBoxLine
              id={paymentTypes.valeCompra}
              key={paymentTypes.valeCompra}
              checked={useCredit}
              value={paymentTypes.valeCompra}
              icon={<ValeCompraIcon className="PaymentBox-icon" />}
              labelText="Vale Compra"
              type={getTypeInputValeCompra(
                credits.remainingCartAmount,
                hasMarketplaceItems
              )}
              description={labelValeCompraOption.description}
              descriptionClassName={labelValeCompraOption.className}
              handleTypeChangeClick={this.handleTypeChangeClick}
              currentType={currentType}
              disabled={parseFloat(credits.totalAvailableAmount) <= 0}>
              {((useCredit ||
                (parseFloat(credits.totalAvailableAmount) <= 0 &&
                  this.state.isPaymentModalOpen)) && (
                <Element name={paymentTypes.valeCompra}>
                  <ValeCompraForm
                    credits={credits}
                    totals={totals}
                    hasMarketplaceItems={hasMarketplaceItems}
                    useCredit={useCredit}
                    handleSubmit={(e) =>
                      handleSubmitPlaceOrder(e, paymentTypes.valeCompra, valeCompraData())
                    }
                  />
                </Element>
              )) ||
                null}
            </PaymentBoxLine>
          )}

          {showPix && !useCredit && (
            <PaymentBoxLine
              checked={currentType === paymentTypes.pix}
              id="pix-line"
              value={paymentTypes.pix}
              icon={<IconPix className="PaymentBox-icon" />}
              labelText="Pix"
              labelTextSpecialMessage="Aprovação em minutos"
              handleTypeChangeClick={this.handleTypeChangeClick}>
              {(currentType === paymentTypes.pix && (
                <Element name="pix-line">
                  <PixForm
                    price={this.getOtherPaymentAmount(paymentTypes.pix)}
                    handleSubmit={(e) =>
                      handleSubmitPlaceOrder(
                        e,
                        paymentTypes.pix,
                        pixData({ hasMarketplaceItems })
                      )
                    }
                  />
                </Element>
              )) ||
                null}
            </PaymentBoxLine>
          )}

          {showSavedCreditCards &&
            cardList
              .filter((card) => card.flag !== cardFlags.unknown)
              .map(({ id, number: cardNumber, fullName, flag, enabled }) => (
                <SavedCardLine
                  key={id}
                  id={id}
                  cardNumber={cardNumber}
                  fullName={fullName}
                  currentType={currentType}
                  checked={currentType === id}
                  flag={flag}
                  enabled={enabled}
                  handleTypeChangeClick={this.handleTypeChangeClick}>
                  {(currentType === id && (
                    <Element name={id}>
                      <SavedCardFormContainer
                        items={products}
                        redirectToAddress={redirectToAddress}
                        redirectToBasket={redirectToBasket}
                        handlePlaceOrderError={handlePlaceOrderError}
                        enableEncryptedCard={enableEncryptedCard}
                        tmxSessionID={tmxSessionID}
                      />
                    </Element>
                  )) ||
                    null}
                </SavedCardLine>
              ))}

          {showNewCreditCard && (
            <PaymentBoxLine
              checked={currentType === paymentTypes.newCard}
              id="newCard-line"
              value={paymentTypes.newCard}
              icon={<CardIcon className="PaymentBox-icon" />}
              labelText="Novo cartão de crédito"
              handleTypeChangeClick={this.handleTypeChangeClick}>
              {(currentType === paymentTypes.newCard && (
                <Element name="newCard-line">
                  <NewCardFormContainer
                    items={products}
                    allowedBins={allowedBins}
                    redirectToAddress={redirectToAddress}
                    redirectToBasket={redirectToBasket}
                    handlePlaceOrderError={handlePlaceOrderError}
                    enableEncryptedCard={enableEncryptedCard}
                    tmxSessionID={tmxSessionID}
                  />
                </Element>
              )) ||
                null}
            </PaymentBoxLine>
          )}

          {showVirtualDebitElo && (
            <PaymentBoxLine
              checked={currentType === paymentTypes.virtualDebitElo}
              id="virtualDebitElo-line"
              value={paymentTypes.virtualDebitElo}
              icon={<VirtualDebitCardIcon className="PaymentBox-icon" />}
              labelText="Cartão de débito virtual Caixa"
              handleTypeChangeClick={this.handleTypeChangeClick}>
              {(currentType === paymentTypes.virtualDebitElo && (
                <Element name="virtualDebitElo-line">
                  <VirtualDebitEloFormContainer
                    items={products}
                    price={this.getOtherPaymentAmount(paymentTypes.virtualDebitElo)}
                    allowedBins={allowedBins}
                    redirectToAddress={redirectToAddress}
                    redirectToBasket={redirectToBasket}
                    handlePlaceOrderError={handlePlaceOrderError}
                    enableEncryptedCard={enableEncryptedCard}
                    tmxSessionID={tmxSessionID}
                  />
                </Element>
              )) ||
                null}
            </PaymentBoxLine>
          )}

          {!hideBankSlip &&
            !useCredit &&
            showBankSlip &&
            !this.hasInternationalProduct() && (
            <PaymentBoxLine
              checked={currentType === paymentTypes.bankSlip}
              id="bankSlip-line"
              value={paymentTypes.bankSlip}
              icon={<BankSlipIcon className="PaymentBox-icon" />}
              labelText="Boleto bancário"
              handleTypeChangeClick={this.handleTypeChangeClick}>
              {(currentType === paymentTypes.bankSlip && (
                <Element name="bankSlip-line">
                  <BankSlipForm
                    price={this.getOtherPaymentAmount(paymentTypes.bankSlip)}
                    expiration={this.getBankSlipExpiration()}
                    handleSubmit={(e) =>
                      handleSubmitPlaceOrder(
                        e,
                        paymentTypes.bankSlip,
                        bankSlipData({ hasMarketplaceItems })
                      )
                    }
                    hasSameDayPickup={storesHaveSameDayPickup(
                      shippingPackages?.shippingTypes?.stores
                    )}
                    hasSameDayDelivery={hasSameDayDelivery(
                      shippingPackages?.shippingTypes?.deliveries?.packages
                    )}
                  />
                </Element>
              )) ||
                  null}
            </PaymentBoxLine>
          )}

          {isMobile && (
            <div className="SecurePurchase">
              <SecurePurchaseIcon className="SecurePurchase-icon" />
              <span className="SecurePurchase-description">
                {' '}
                Compra segura com Magalu{' '}
              </span>
            </div>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({
  basket,
  form,
  installments,
  order,
  cardList,
  allowedBins,
  channel,
  deliveries,
}) => ({
  form,
  ...installments,
  ...order,
  ...cardList,
  ...allowedBins,
  ...basket,
  ...deliveries,
  showBankSlip: channel.configs.show_bank_slip ?? true,
  showPix: channel.configs.enable_pix ?? false,
  enablePhoneSales: channel.configs.enable_phone_sales,
  blackListedSku: channel.configs.disabled_bankslip_skus,
  showNewCreditCard: channel.configs.show_new_creditcard ?? true,
  showVirtualDebitElo: channel.configs.show_virtual_debit_elo ?? false,
  showSavedCreditCards: channel.configs.show_saved_creditcards ?? true,
  showValeCompra: channel.configs.show_credit_voucher ?? true,
  enableEncryptedCard: channel.configs.enable_encrypted_card ?? false,
});

const mapDispatchToActions = {
  ...orderActions,
  ...installmentsActions,
  ...basketActions,
};

export default Responsive(
  connect(mapStateToProps, mapDispatchToActions)(withModal(PaymentBoxContainer))
);
