import { checkoutUrl } from 'constants/api-urls';

import request from 'utils/request';

import {
  simpleAddress as simpleAddressModel,
  addressData as addressDataModel,
  customerAddress as customerAddressModel,
} from 'api/models/address-models';


export const getAddress = (zipcode) => {
  return request('GET', `${checkoutUrl}/zipcode/${zipcode}/`)
    .then(res => res.body)
    .then(json => simpleAddressModel(json));
};

export const putAddress = (address) => {
  const data = addressDataModel(address);

  return request('PUT', `${checkoutUrl}/address/save/`)
    .send(data)
    .then(res => res.body)
    .then(json => customerAddressModel(json.data));
};

export const getAddressList = (showAll = true) => {
  return request('GET', `${checkoutUrl}/address/list/`)
    .query({ show_all: showAll })
    .then(res => res.body)
    .then(json => (
      json.data && (
        // here we have a list of addresses - showAll is probably true
        json.data.map(customerAddressModel)
      ) || (
        // redirect: true because we should redirect the user to the next
        // page if the backend returns only the json.address node.
        [Object.assign(
          {},
          customerAddressModel(json.address),
          { redirect: true })]
      )
    ));
};

export const patchAddress = (addressUuid) => {
  return request('PATCH', `${checkoutUrl}/cart/update/`)
    .send({
      shipping_address_id: addressUuid,
      billing_address_id: addressUuid
    });
};
