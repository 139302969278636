import cookies from 'browser-cookies';
import { lowerDash } from 'utils/data-layer/helpers/lowerDash';
import { generateDimensionsDataGA4 } from './generateDimensionsDataGA4';
import { productName } from './productName';
import { productIdPrimaryByCookie } from './productIdPrimaryByCookie';
import { getProductAvailabilityStatus } from './getProductAvailabilityStatus';
import { itemVariantParser } from './variantParser';
import { onlyCharactersAndSpace } from 'utils/value-cleaners';
import getCategory5Label from './getCategory5Label';

const productPrice = (product, eventName) => {
  const price = product?.unit_price || product?.prices?.to || product?.prices?.cash;
  const quantity = product?.quantity || 1;
  const discount = product?.payment_discount_amount || 0;

  let total; 
  if(eventName === 'view_cart') total = parseFloat(((price - discount) / quantity).toFixed(2));
  else total = parseFloat((price - discount / quantity).toFixed(2));
  return Number(total);
};

export const generateProductDataGA4 = ({ product, stateAfter, index, eventName }) => {
  const { name, title, brand, quantity, category_id, subcategory_id } = product;

  const productId = productIdPrimaryByCookie(
    cookies.get('ga_cookie_add'),
    product.id || product.sku
  );
  const provider = lowerDash(product.seller || product.storeId) || null;

  const findProduct = stateAfter?.basket.basket?.products?.find(
    (item) => item.sku === product?.sku
  );

  const productBasketSoldBy = `${provider}|ent-por:${findProduct?.provider || null}`;
  const itemVariant = itemVariantParser(product.variations);
  const productUrl = product?.productUrl || product?.path_url || product?.link;
  const urlParts = productUrl?.split('/') ?? null;
  const categories = urlParts
    ? `${urlParts[urlParts.length - 3]}/${urlParts[urlParts.length - 2]}`
    : null;

  const discountPrices = product?.prices
    ? parseFloat(
      (parseFloat(product?.prices?.to) - parseFloat(product?.prices?.cash)).toFixed(2)
    )
    : null;
  const reviewCount = product?.review_count || product?.reviewCount || 'null';
  const reviewScore = product?.review_score || product?.reviewScore || 'null';

  const productData = {
    item_id: productId,
    item_name: productName(name || title),
    item_brand: productName(brand) || null,
    item_category:
      categories ?? `${productName(category_id)}/${productName(subcategory_id)}` ?? null,
    item_category3: `qtd-av:${reviewCount}|av:${reviewScore}|${getProductAvailabilityStatus(
      product.isSoldOut,
      product.notAvailableForDelivery
    )}`,
    item_category4: `ven-por:${productBasketSoldBy}`,
    item_category5: `selo:${
    lowerDash(onlyCharactersAndSpace(product?.badges?.[0]?.text)) || null
      }|tag:${lowerDash(onlyCharactersAndSpace(product?.offerTags?.[0])) || getCategory5Label(product)}`,
    discount: discountPrices ?? product?.payment_discount_amount,
    item_variant: itemVariant,
    price: productPrice(product, eventName),
    quantity: quantity || 1,
    ...generateDimensionsDataGA4(stateAfter, product, productId, index, eventName),
  };

  if (eventName === 'purchase') {
    const coupon = product?.discounts?.find((discount) =>
      discount.description.includes('Promocode:')
    );
    productData.coupon = coupon
      ? productName(coupon.description?.split(':').pop())
      : null;
    productData.affiliation = 'magalu';
  }

  return productData;
};

export function productsDataGA4({ products = [], stateAfter, index, eventName = '' }) {
  return products.map((product) =>
    generateProductDataGA4({ product, stateAfter, index, eventName })
  );
}
