import { inRange } from 'lodash';
import moment from 'moment';
import {
  DEFAULT_CARDS, detectCardFlag, getMaxCardLength, getMaxCVCLength,
  getMinCardLength, getMinCVCLength
} from './card';

export const zipcode = (value) => {
  return value && value.length === 8 || false;
};

export const fullName = value => {
  return value && value.trim().split(' ').length > 1 || false;
};

export const motherName = value => {
  return value && value.trim().split(' ').length > 1 || false;
};

export const email = (value) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,20})$/i; // eslint-disable-line security/detect-unsafe-regex
  return emailRegex.test(value);
};

export const cpfOrCnpj = (value) => {
  return value && value.length === 11 || value.length === 14 || false;
};

export const telephone = (value) => {
  return value && value.length === 10 || value.length === 11 || false;
};

export const mobilePhone = (value) => {
  return value && value.length === 11 || false;
};

export const mobilePhoneCustomerArea = (value) => {
  return /^(\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[9])(?!(\d)\4{7})\d{4})-?(\d{4}))$/.test(value); // eslint-disable-line security/detect-unsafe-regex
};

export const date = (value) => {
  return value && /\d{2}\/\d{2}\/\d{4}/.test(value) && moment(value, 'DD/MM/YYYY').isValid() || false;
};

/**
 * This function validates if bin number of the card is valid by Luhn algorithm
 * Reference: https://en.wikipedia.org/wiki/Luhn_algorithm
 */
export const validateCardNumber = (value) => {
  const trimmed = String(value).replace(/\s/g, '');
  const length = trimmed.length;
  let odd = false;
  let total = 0;
  let calc = 0;
  let calc2 = 0;

  if (length === 0) {
    return true;
  }

  if (!/\d{1,19}$/.test(trimmed)) {
    return false;
  }

  for (let i = length; i > 0; i--) {
    calc = parseInt(trimmed.charAt(i - 1), 10);
    if (!odd) {
      total += calc;
    } else {
      calc2 = calc * 2;

      switch (calc2) {
        case 10: calc2 = 1; break;
        case 12: calc2 = 3; break;
        case 14: calc2 = 5; break;
        case 16: calc2 = 7; break;
        case 18: calc2 = 9; break;
        default: break;
      }
      total += calc2;
    }
    odd = !odd;
  }

  return ((total % 10) === 0);
};

export const isValidCardNumber = (value) => {
  const validluhn = detectCardFlag(value) !== 'hipercard' ? validateCardNumber(value) : true;
  return validluhn && !!value && inRange(value.length, getMinCardLength(), getMaxCardLength() + 1);
};

export const isValidVirtualDebitEloCardNumber = (value) => {
  return new RegExp(DEFAULT_CARDS.virtual_debit_elo.regex).test(value);
};

export const isCardNumberBinAllowed = (value, bins) => {
  if (!value || bins.length === 0) return true;
  let cardBin = value.substring(0, 6);
  return bins.some(allowed => cardBin === allowed);
};

export const cvc = (value, flag = '', cards = DEFAULT_CARDS) => {
  if (! value) {
    return false;
  }

  if (! flag) {
    return value.length === getMinCVCLength(cards) || value.length === getMaxCVCLength(cards);
  }

  return value.length === cards[flag].cvcLength;
};

export const password = (value) => {
  return value && value.length >= 6 && value.length <= 20 || false;
};

export const expirationDate = (expirationMonth, expirationYear) => {
  const expirationMoment = moment(`${expirationMonth}/${expirationYear}`, 'MM/YYYY');

  return expirationMonth >= 1 && expirationYear >= 1 && expirationMoment.isValid() && expirationMoment.endOf('month') >= moment();
};

export const birthDate = (value) => {
  if (! value) {
    return false;
  }

  const birthDateMoment = moment(value, 'DD/MM/YYYY');
  const birthDateDiff = moment().diff(birthDateMoment, 'years');

  return date(value) && birthDateDiff >= 18 && birthDateDiff <= 110 || false;
};

export const isValidCnpj = (val) => {
  let cnpj = val;

  if (! cnpj) {
    return false;
  }

  const valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
  let dig1 = null;
  let dig2 = null;

  cnpj = cnpj.toString().replace(/[^\d]+/g, '');
  const digito = parseInt(`${cnpj.charAt(12)}${cnpj.charAt(13)}`, 10);

  if (cnpj.length !== 14) {
    return false;
  }

  for (let i = 0; i < valida.length; i++) {
    dig1 += (i > 0 ? (cnpj.charAt(i - 1) * valida[i]) : 0);
    dig2 += cnpj.charAt(i) * valida[i];
  }
  dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
  dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));

  return (dig1 * 10) + dig2 === digito;
};

export const isValidCpf = (val) => {
  let strCPF = val;

  if (! strCPF) {
    return false;
  }

  let soma = 0;
  let resto;
  const exp = /[^\d]/g;
  strCPF = strCPF.toString().replace(exp, '');

  if (strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' || strCPF === '44444444444' ||
    strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' ||
    strCPF === '99999999999' || strCPF === '00000000000' || strCPF.length !== 11) {
    return false;
  }

  let i = 0;
  for (i = 1; i <= 9; i++) {
    soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }

  if (resto !== parseInt(strCPF.substring(9, 10), 10)) {
    return false;
  }

  soma = 0;
  for (i = 1; i <= 10; i++) {
    soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }

  return resto === parseInt(strCPF.substring(10, 11), 10);
};

export const promocode = (value) => {
  return /^[\w-]{1,20}$/.test(value);
};

export const isAuthenticationByCpf = (typeAuthentication) => {
  return (!!typeAuthentication &&
    typeAuthentication === 'colaborador' ||
    typeAuthentication === 'cpf' ||
    typeAuthentication === 'sindicato');
};

export const isAuthenticationByEmail = (typeAuthentication) => {
  return (!!typeAuthentication &&
    typeAuthentication === 'email');
};

export const rg = (value) => {
  return value && value.length > 5 && value.length <= 20;
};

export const rgAuthority = (value) => {
  return value && value.length >= 3 && value.length <= 50;
};

export const complement = (value) => {
  return value && value.length <= 15;
};

export const reference = (value) => {
  return value && value.trim().length > 5;
};

export const pin = (value) => {
  return value && value.length === 6 || false;
};

export const isNumber = (value) => {
  return /[0-9]/.test(value);
};
