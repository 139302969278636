import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import cookies from 'browser-cookies';

import * as customerMessages from 'constants/customer-messages';
import * as routeNames from 'constants/route-names';

import { getNextUrl, getOriginUrl } from 'utils/login';
import { redirectToNextUrl } from 'utils/redirect';
import { initSmartlock } from 'utils/smartlock';
import * as validators from 'utils/validators';

import { postOneTap } from 'api/customer-api';
import { GOOGLE } from 'constants/social-login';
import { BUTTON_LOGIN_ID } from 'constants/ids';
import { stwuCookieName } from 'constants/stewie-constants';

import { fail } from 'reducers/fail';
import { toggleProfilingIsComplete } from 'reducers/tmx';
import { toggleLoading } from 'reducers/loading';
import * as loadingActions from 'reducers/loading';
import * as loginActions from 'reducers/login';
import * as metricsActions from 'reducers/metrics';
import * as signupActions from 'reducers/signup-available';
import * as socialLoginActions from 'reducers/social-login';

import Captcha, { validateCaptcha } from 'components/Form/Captcha/Captcha';
import GoogleOneTap from 'components/Login/GoogleOneTap';
import LoginBox from 'components/Login/LoginBox/LoginBox';
import Threatmetrix from '../../../vendor/ThreatMetrix';
import { adsCollectorLogin } from 'utils/magalu-ads-collector/magalu-ads-collector';

const { string, shape, object, func, bool } = PropTypes;

const tmxLoadingID = 'tmxProfiling';

export const isLoginValid = (login, configs) => {
  if (
    !!configs.type_authentication &&
    validators.isAuthenticationByCpf(configs.type_authentication)
  ) {
    return validators.isValidCpf(login);
  } else if (
    !!configs.type_authentication &&
    validators.isAuthenticationByEmail(configs.type_authentication)
  ) {
    return validators.email(login);
  }
  return (
    validators.isValidCpf(login) ||
    validators.isValidCnpj(login) ||
    validators.email(login)
  );
};

export const validate = ({ login, password }, props) => {
  const errors = {};
  if (login && !isLoginValid(login, props.channelConfigs)) {
    if (
      !!props.channelConfigs.type_authentication &&
      validators.isAuthenticationByCpf(props.channelConfigs.type_authentication)
    ) {
      errors.login = customerMessages.invalidCPF;
    } else if (
      !!props.channelConfigs.type_authentication &&
      validators.isAuthenticationByEmail(props.channelConfigs.type_authentication)
    ) {
      errors.login = customerMessages.invalidEmail;
    } else {
      const enablePJRedirect =
        props.channelConfigs.enable_pj_customer_redirect_to_magalu_empresas ?? false;
      errors.login = enablePJRedirect
        ? customerMessages.invalidLoginEnabledRedirect
        : customerMessages.invalidLogin;
    }
  }

  if (login && isLoginValid(login, props.channelConfigs) && !password) {
    errors.password = customerMessages.blankPassword;
  }

  return errors;
};

const validateValues = ({ login, password }, configs) => {
  const errors = {};

  if (!isLoginValid(login, configs)) {
    errors.login = customerMessages.invalidLogin;
  }

  if (!password) {
    errors.password = customerMessages.blankPassword;
  }

  return errors;
};

const submitValidLogin = (
  channel,
  location,
  router,
  query,
  postLogin,
  failFunc,
  values,
  captchaToken,
  resolve,
  reject,
  loadingHide
) => {
  const { login, password } = values;
  const { origin, next, tipo, ...nextQuery } = query;
  const cookieStwu = cookies.get(stwuCookieName);

  return postLogin(login, password, channel, captchaToken)
    .then(async (res) => {
      if ('redirect' in res && res.redirect === true) {
        return Promise.resolve();
      }
      await adsCollectorLogin(res.customerId, cookieStwu);
      loadingHide('submitLogin');
      resolve();
      if (!isEmpty(res.registrationErrors)) {
        failFunc(customerMessages.incompleteRegister);

        if (!origin) {
          return router.replace({
            pathname: `/${routeNames.editRegistration}`,
            query,
          });
        }

        return router.replace({
          pathname: `/${routeNames.channelEditRegistration}`,
          query,
        });
      }

      const nextUrl = next || getNextUrl(location.pathname);

      const originUrl = origin || getOriginUrl(nextUrl);

      redirectToNextUrl(router.replace, {
        next: nextUrl,
        origin: originUrl,
        nextQuery: { tipo, ...nextQuery },
      });
    })
    .catch((err) => {
      loadingHide('submitLogin');
      if (err && err.status === 422) {
        return reject();
      }
      throw err;
    });
};

const mapStateToProps = ({ login, channel, tmx }) => ({
  ...login,
  channelName: channel.name,
  channelConfigs: channel.configs,
  useCaptcha: channel.configs.use_captcha ?? true,
  smartlock: channel.configs.smartlock,
  smartlockClientId: channel.configs.smartlock_client_id,
  useOneTap: channel.configs.use_onetap,
  googleClientId: channel.configs.google_client_id,
  useSocialLogin: channel.configs.use_social_login ?? true,
  profilingIsComplete: tmx.profilingIsComplete,
});
const mapDispatchToProps = {
  ...loginActions,
  loginTrackError: loginActions.trackError,
  loginTrackSubmit: loginActions.trackSubmit,
  failFunc: fail,
  ...loadingActions,
  ...socialLoginActions,
  ...signupActions,
  ...metricsActions,
  toggleProfilingIsComplete,
  toggleLoading,
};

@reduxForm(
  {
    form: 'loginBox',
    fields: ['login', 'password'],
    validate,
  },
  mapStateToProps
)
@connect(mapStateToProps, mapDispatchToProps)
export default class LoginBoxContainer extends Component {
  static propTypes = {
    channelName: string.isRequired,
    channelConfigs: object.isRequired,
    fields: shape({
      login: object.isRequired,
      password: object.isRequired,
    }).isRequired,
    location: object.isRequired,
    handleSubmit: func.isRequired,
    postLogin: func.isRequired,
    failFunc: func.isRequired,
    errorMessage: string,
    isRequesting: bool.isRequired,
    useCaptcha: bool,
    smartlock: bool,
    smartlockClientId: string,
    showLoading: func.isRequired,
    hideLoading: func.isRequired,
    receiveProfile: func.isRequired,
    receiveLoginAvailable: func.isRequired,
    metricsError: func.isRequired,
    useSocialLogin: bool,
    toggleProfilingIsComplete: func.isRequired,
    toggleLoading: func.isRequired,
    profilingIsComplete: bool.isRequired,
  };

  static contextTypes = {
    router: object.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.preLogin(context.router);
  }

  state = {
    showPassword: false,
    isTryingLogin: false,
  };

  componentWillUnmount() {
    if (this.props.smartlock && !!window.googleyolo) {
      googleyolo.cancelLastOperation();
    }

    if (this.props.useOneTap) {
      try {
        window.google.accounts.id.cancel();
      } catch (error) {
        return;
      }
    }
  }

  preLogin(router) {
    const {
      location: { query },
    } = this.props;
    const { origin, next, ...nextQuery } = query;
    initSmartlock(
      this.props.smartlock,
      this.props.smartlockClientId,
      this.props.receiveProfile,
      this.props.metricsError,
      this.props.showLoading,
      this.props.hideLoading
    )
      .then((credential) => {
        if (credential.password) {
          this.props.handleSubmit(
            this.submitLoginCredentials(
              this.props.channelName,
              this.props.location,
              router,
              this.props.location.query,
              this.props.postLogin,
              this.props.failFunc,
              credential
            )
          );
        }

        if (credential.clientId || credential.idToken) {
          const nextUrl = next || getNextUrl(location.href);
          const originUrl = origin || getOriginUrl(nextUrl);

          redirectToNextUrl(router.replace, {
            next: nextUrl,
            origin: originUrl,
            nextQuery,
          });
        }
      })
      .catch((error) => {
        this.props.hideLoading('submitLoginSmartlock');
        if (error === 'No data found in response') {
          this.props.receiveLoginAvailable(true);
          redirectToNextUrl(router.push, {
            next: routeNames.signup,
            nextQuery: query,
          });
        }
      });
  }

  submitLogin = (channel, location, router, query, postLogin, failFunc) => {
    return (values) => {
      this.props.loginTrackSubmit();

      const errors = validateValues(values, this.props.channelConfigs);
      if (!isEmpty(errors)) {
        this.props.loginTrackError(JSON.stringify(errors));
        return Promise.reject(errors);
      }

      return validateCaptcha('login').then((captchaToken) => {
        return new Promise((resolve, reject) => {
          this.props.showLoading('submitLogin');

          submitValidLogin(
            channel,
            location,
            router,
            query,
            postLogin,
            failFunc,
            values,
            captchaToken,
            resolve,
            reject,
            this.props.hideLoading
          );
        });
      });
    };
  };

  submitLoginCredentials = (
    channel,
    location,
    router,
    query,
    postLogin,
    failFunc,
    credentials
  ) => {
    const values = {
      login: credentials.id,
      password: credentials.password,
    };

    return validateCaptcha('login_credentials').then((captchaToken) => {
      return new Promise((resolve, reject) => {
        submitValidLogin(
          channel,
          location,
          router,
          query,
          postLogin,
          failFunc,
          values,
          captchaToken,
          resolve,
          reject,
          this.props.hideLoading
        );
      });
    });
  };

  redirectParams = () => {
    const { origin, next, ...nextQuery } = this.props.location.query;
    const nextUrl = next || getNextUrl(location.href);
    const originUrl = origin || getOriginUrl(nextUrl);

    return {
      next: nextUrl,
      origin: originUrl,
      nextQuery,
    };
  };

  oneTapSuccess = () => {
    const { router } = this.context;
    redirectToNextUrl(router.replace, this.redirectParams());
  };

  oneTapError = (label, message) => {
    this.props.metricsError(label, message);
    this.props.failFunc(customerMessages.loginFail);
  };

  oneTapUserNotFound = (profileInfo) => {
    const { router } = this.context;
    this.props.receiveLoginAvailable(true);
    this.props.receiveProfile(profileInfo);
    redirectToNextUrl(router.push, {
      next: routeNames.signup,
      ...this.redirectParams(),
    });
  };

  handleOneTapCredential = ({ credential }) => {
    this.props.showLoading('submitLoginOneTap');

    postOneTap(credential)
      .then((oneTapResponse) => {
        this.props.hideLoading('submitLoginOneTap');

        if (oneTapResponse.clientId) {
          this.oneTapSuccess();
        } else {
          return this.oneTapError('OneTapResponse error', oneTapResponse);
        }
      })
      .catch((error) => {
        this.props.hideLoading('submitLoginOneTap');

        if (error.status !== 404) {
          return this.oneTapError(`OneTap ${error.status}`, error.message);
        }

        const profileInfo = {
          source: GOOGLE,
          email: error.response.body.email,
          socialClientId: error.response.body.social_client_id,
        };

        this.oneTapUserNotFound(profileInfo);
      });
  };

  showProfilingLoading = () => {
    this.props.toggleLoading(tmxLoadingID, true);
  };

  hideProfilingLoading = () => {
    this.props.toggleLoading(tmxLoadingID, false);
  };

  handleLoginWithoutProfilingIsComplete = () => {
    this.setState({ isTryingLogin: true });
    this.showProfilingLoading();
  };

  handleClickLoginCallback = () => {
    document.getElementById(BUTTON_LOGIN_ID).click();
  };

  render() {
    const {
      channelName,
      fields: { login, password },
      handleSubmit,
      postLogin,
      location,
      location: { query },
      failFunc,
      useCaptcha,
      useSocialLogin,
      profilingIsComplete,
    } = this.props;
    const { router } = this.context;

    return (
      <div>
        <LoginBox
          loginField={login}
          passwordField={password}
          handleSubmit={
            profilingIsComplete
              ? handleSubmit(
                  this.submitLogin(
                    channelName,
                    location,
                    router,
                    query,
                    postLogin,
                    failFunc
                  )
                )
              : handleSubmit(this.handleLoginWithoutProfilingIsComplete)
          }
          showPassword={this.state.showPassword}
          errorMessage={this.props.errorMessage}
          isRequesting={this.props.isRequesting}
          handleShowPasswordClick={(statePassword) => {
            this.setState({ [statePassword]: !this.state[statePassword] });
          }}
        />

        {useCaptcha ? <Captcha /> : null}

        {!this.props.smartlock && this.props.useOneTap && useSocialLogin ? (
          <GoogleOneTap
            googleClientID={this.props.googleClientId}
            handleOneTapCredential={this.handleOneTapCredential}
          />
        ) : null}
        <Threatmetrix
          toggleProfilingIsComplete={this.props.toggleProfilingIsComplete}
          hideProfilingLoading={this.hideProfilingLoading}
          handleLogin={this.handleClickLoginCallback}
          profilingIsComplete={profilingIsComplete}
          isTryingLogin={this.state.isTryingLogin}
        />
      </div>
    );
  }
}
