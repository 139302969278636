import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import * as customerMessages from 'constants/customer-messages';
import * as routeNames from 'constants/route-names';

import { setSecondOtpId, getFirstOtpId } from 'utils/manageOtpIds';
import * as maskers from 'utils/maskers';
import * as cleaners from 'utils/value-cleaners';
import * as validators from 'utils/validators';

import * as signupActions from 'reducers/signup-available';
import * as loginActions from 'reducers/login';
import * as otpActions from 'reducers/send-otp';
import * as customerAreaActions from 'reducers/customer-area';

import ChangeDataBox from './ChangeDataBox/ChangeDataBox';
import { validateCaptcha } from 'components/Form/Captcha/Captcha';

const validateValues = ({ login }) => {
  const errors = {};

  if (!validators.email(login)) {
    errors.login = customerMessages.blankSignupEmailCustomerArea;
  }

  return errors;
};

const mapStateToProps = ({ signupAvailable }) => ({
  ...signupAvailable,
});

const mapDispatchToActions = {
  ...signupActions,
  ...loginActions,
  ...otpActions,
  trackDataChange: customerAreaActions.trackDataChange,
  trackDataChangeError: customerAreaActions.trackDataChangeError,
};

const validate = ({ login, loginConfirm, telephone, telephoneConfirm }) => {
  const errors = {};

  if (login && !validators.email(login)) {
    errors.login = customerMessages.invalidEmailCustomerArea;
  }

  if (login && !loginConfirm) {
    errors.loginConfirm = customerMessages.blankSignupEmailCustomerArea;
  }

  if (loginConfirm && login !== loginConfirm) {
    errors.loginConfirm = customerMessages.invalidEmailConfirmCustomerArea;
  }

  if (telephone && !validators.mobilePhoneCustomerArea(cleaners.onlyNumbers(telephone))) {
    errors.telephone = customerMessages.invalidTelephoneCustomerArea;
  }

  if (telephone && !telephoneConfirm) {
    errors.telephoneConfirm = customerMessages.invalidTelephoneCustomerArea;
  }

  if (telephoneConfirm && telephone !== telephoneConfirm) {
    errors.telephoneConfirm = customerMessages.invalidTelephoneConfirmCustomerArea;
  }

  return errors;
};

@reduxForm({
  form: 'customerAreaChangeData',
  fields: ['login', 'telephone', 'loginConfirm', 'telephoneConfirm'],
  validate,
})
@connect(mapStateToProps, mapDispatchToActions)
export default class ChangeEmailCellphone extends Component {
  static propTypes = {
    changeEmail: PropTypes.bool,
    fields: PropTypes.object,
    customerInfo: PropTypes.object,
    fetchLoginAvailable: PropTypes.func,
    handleSubmit: PropTypes.func,
    postOtpCode: PropTypes.func,
    updateCustomTo: PropTypes.func,
    location: PropTypes.object,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  generateOtpForEdit = async () => {
    const { changeEmail, fields, postOtpCode, updateCustomTo, location } = this.props;
    const { router } = this.context;
    const finalFields = Object.assign({}, fields, {
      telephone: Object.assign({}, fields.telephone, { telephone: !!telephone }),
      login: Object.assign({}, fields.login, {
        disabled: !!login,
      }),
    });
    const { login, telephone } = finalFields;
    const selectedEmail = window.location.hash.includes('email');
    const to = changeEmail ? login.value : telephone.value.replace(/(^(\+)|\D)/g, '');
    const optionsData = {
      delivery_mode: changeEmail ? 'email' : 'sms',
      to,
      session_id: getFirstOtpId(),
    };

    const { id } = await postOtpCode(optionsData, 'secondOtp');

    if (id) {
      updateCustomTo({ [selectedEmail ? 'email' : 'phone']: to });
      setSecondOtpId(id);
      const query = selectedEmail ? 'email' : 'sms';
      router.push(
        `${routeNames.channelCustomerAreaConfirm}?opcao=${query}${
          location.query.deeplink ? '&deeplink=app' : ''
        }`
      );
    }
  };

  submitWithEmailVerification = (fetchLoginAvailable) => {
    return (values) => {
      const errors = validateValues(values);
      const { trackDataChange } = this.props;

      if (!values.login || !isEmpty(errors)) {
        return Promise.reject(errors);
      }
      trackDataChange('email');
      return validateCaptcha('pre_signup_email').then((captchaToken) => {
        return new Promise((resolve, reject) => {
          const { login } = values;
          const { trackDataChangeError } = this.props;
          return fetchLoginAvailable(captchaToken, undefined, login)
            .then(() => {
              this.generateOtpForEdit();
              resolve();
            })
            .catch(({ status }) => {
              const errorMessage =
                status === 403
                  ? customerMessages.blockEmail
                  : customerMessages.emailAlreadyRegisteredCustomerArea;
              reject({ login: errorMessage });
              trackDataChangeError('email', errorMessage);
            });
        });
      });
    };
  };

  submitTelephone = () => {
    return () => {
      const { fields, trackDataChange, customerInfo } = this.props;
      const newPhone = fields.telephone.value.replace(/(^(\+)|\D)/g, '');

      if (customerInfo.telephone === newPhone) {
        return Promise.reject({
          telephone: customerMessages.numberIsTheSame,
        });
      }

      if (!fields.telephone?.value || fields.telephone?.error) {
        return Promise.resolve();
      }

      trackDataChange('telefone');
      this.generateOtpForEdit();
    };
  };

  handleChangeLogin = (event) => {
    this.props.fields.login.onChange(event.target.value.toLowerCase());
  };

  handleChangeLoginConfirm = (event) => {
    this.props.fields.loginConfirm.onChange(event.target.value.toLowerCase());
  };

  render() {
    const { fields, handleSubmit, customerInfo, changeEmail, fetchLoginAvailable } =
      this.props;
    const finalFields = Object.assign({}, fields, {
      telephone: Object.assign({}, fields.telephone, { telephone: !!telephone }),
      telephoneConfirm: Object.assign({}, fields.telephoneConfirm, {
        telephoneConfirm: !!telephoneConfirm,
      }),
      login: Object.assign({}, fields.login, {
        disabled: !!login,
      }),
      loginConfirm: Object.assign({}, fields.loginConfirm, {
        disabled: !!loginConfirm,
      }),
    });
    const { login, telephone, loginConfirm, telephoneConfirm } = finalFields;
    const modeProps = {
      dataMode: changeEmail ? 'email' : 'telephone',
      dataModeConfirm: changeEmail ? 'emailConfirm' : 'telephoneConfirm',
      customerData: changeEmail
        ? customerInfo.email
        : maskers.mobilePhoneApp(customerInfo.telephone),
      dataLineJump: changeEmail ? true : false,
      handleSubmit: changeEmail
        ? handleSubmit(this.submitWithEmailVerification(fetchLoginAvailable))
        : handleSubmit(this.submitTelephone()),
      field: changeEmail ? login : telephone,
      fieldConfirm: changeEmail ? loginConfirm : telephoneConfirm,
      handleChangeField: changeEmail ? this.handleChangeLogin : false,
      handleChangeFieldConfirm: changeEmail ? this.handleChangeLoginConfirm : false,
    };

    return <ChangeDataBox {...modeProps} />;
  }
}
