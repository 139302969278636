import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute } from 'react-router';
import { UPDATE_LOCATION } from 'redux-simple-router';
import { animateScroll } from 'react-scroll';

import store, { history } from 'store';

import * as routeNames from 'constants/route-names';

import { detectMobile } from 'utils/responsive';
import verifyIfMiniapp from 'utils/verifyIfMiniapp';

import CheckoutChannelFooterContainer from 'containers/CheckoutChannelFooterContainer';
import CheckoutChannelHeaderContainer from 'containers/CheckoutChannelHeaderContainer';
import ChannelFooterContainer from 'containers/ChannelFooterContainer';
import ChannelHeaderContainer from 'containers/ChannelHeaderContainer';
import App from 'containers/App/App';
import NotFound from 'containers/NotFound';
import Logout from 'containers/Logout';

import BasketPage from 'pages/BasketPage/BasketPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import SignupPage from 'pages/SignupPage/SignupPage';
import AddressPage from 'pages/AddressPage/AddressPage';
import PaymentPage from 'pages/PaymentPage/PaymentPage';
import ReceiptPage from 'pages/ReceiptPage/ReceiptPage';
import EditRegistrationPage from 'pages/EditRegistrationPage/EditRegistrationPage';
import CustomerAreaPage from 'pages/CustomerAreaPage/CustomerAreaPage';
import DeliveryPage from 'pages/DeliveryPage/DeliveryPage';
import ConfirmOrderPage from 'pages/ConfirmOrderPage/ConfirmOrderPage';
import CustomerAreaConfirmPage from 'pages/CustomerAreaConfirmPage/CustomerAreaConfirmPage';
import CustomerAreaPageChangeData from 'pages/CustomerAreaPageChangeData/CustomerAreaPageChangeData';

import TraceProvider from 'components/Telemetry/Telemetry';

import 'index.scss';

if (SETTINGS && SETTINGS.version) {
  // eslint-disable-next-line no-console
  console.info(
    '%c 🛒 %s v%s ',
    'background:#0086ff; color:#fff; padding:2px 0;',
    window.MAGALU_CHANNEL?.channel_name || 'magazineluiza',
    SETTINGS.version
  );
}

window.dataLayer = window.dataLayer || [];
history.listen((location) => {
  // Do not send to GTM this pageview, because there is a logic inside the page
  // where we only send the pageview if we do not redirect the user to the next page
  if (location.pathname !== `/${routeNames.address}`) {
    window.dataLayer.push({
      event: 'location',
      type: UPDATE_LOCATION,
      payload: location,
    });
  }
});
history.listen(() => animateScroll.scrollToTop({ smooth: false, duration: 0 }));

if (!!window.MAGALU_CHANNEL.channel_configs.campaign) {
  document.body.classList.add(window.MAGALU_CHANNEL.channel_configs.campaign);
}

const checkoutPage = (pageComponent) => ({
  header: CheckoutChannelHeaderContainer,
  main: pageComponent,
  footer: CheckoutChannelFooterContainer,
});

const isMobileComponent = detectMobile();

const sitePageHeader = isMobileComponent
  ? CheckoutChannelHeaderContainer
  : ChannelHeaderContainer;

const sitePageFooter = isMobileComponent
  ? CheckoutChannelFooterContainer
  : ChannelFooterContainer;

const sitePage = (pageComponent) => ({
  header: sitePageHeader,
  main: pageComponent,
  footer: sitePageFooter,
});

const miniAppPage = (pageComponent) => ({
  header: verifyIfMiniapp() ? Fragment : CheckoutChannelHeaderContainer,
  main: pageComponent,
  footer: verifyIfMiniapp() ? Fragment : CheckoutChannelFooterContainer,
});

render(
  <TraceProvider>
    <Provider store={store}>
      <Router history={history}>
        <Route path={`/${routeNames.root}`} component={App}>
          <IndexRoute components={checkoutPage(BasketPage)} />
          <Route path={routeNames.login} components={checkoutPage(LoginPage)} />
          <Route path={routeNames.logout} components={{ main: Logout }} />
          <Route path={routeNames.signup} components={checkoutPage(SignupPage)} />
          <Route path={routeNames.address} components={checkoutPage(AddressPage)} />
          <Route path={routeNames.delivery} components={checkoutPage(DeliveryPage)} />
          <Route path={routeNames.payment} components={checkoutPage(PaymentPage)} />
          <Route
            path={routeNames.editRegistration}
            components={checkoutPage(EditRegistrationPage)}
          />
          <Route path={routeNames.receipt} components={{ main: ReceiptPage }} />
          <Route path={routeNames.confirmOrder} components={sitePage(ConfirmOrderPage)} />
          <Route path={routeNames.channelLogin} components={sitePage(LoginPage)} />
          <Route path={routeNames.channelSignup} components={sitePage(SignupPage)} />
          <Route
            path={routeNames.channelEditRegistration}
            components={sitePage(EditRegistrationPage)}
          />
          <Route
            path={routeNames.channelCustomerArea}
            components={miniAppPage(CustomerAreaPage)}
          />
          <Route
            path={routeNames.channelCustomerAreaConfirm}
            components={miniAppPage(CustomerAreaConfirmPage)}
          />
          <Route
            path={routeNames.channelCustomerAreaChangeEmail}
            components={miniAppPage(CustomerAreaPageChangeData)}
          />
          <Route
            path={routeNames.channelCustomerAreaChangeCellphone}
            components={miniAppPage(CustomerAreaPageChangeData)}
          />
        </Route>
        <Route path="*" component={NotFound} />
      </Router>
    </Provider>
  </TraceProvider>,
  document.getElementById('root')
);

if (module.hot) module.hot.accept();
