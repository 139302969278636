import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { metricsError } from './metrics';

export const REQUEST = 'login/REQUEST';
export const RECEIVED = 'login/RECEIVED';
export const LOGIN_RECEIVED_GA4 = 'login/LOGIN_RECEIVED_GA4';
export const ERROR = 'login/ERROR';
export const CLEAR_ERROR = 'login/CLEAR-ERROR';
export const TRACK_ERROR = 'login/TRACK_ERROR';
export const TRACK_SUBMIT = 'login/TRACK_SUBMIT';
export const RESET = 'login/RESET';
export const FINISHED = 'login/FINISHED';
export const LOGIN_VIEW = 'login/LOGIN_VIEW';
export const LOGIN_CHECKOUT_PROGRESS = 'login/LOGIN_CHECKOUT_PROGRESS';

export const initialState = {
  isRequesting: false,
  login: '',
  userInfo: {},
  errorMessage: '',
};

export default function reducer(state = initialState, action = {}) {
  // This new state will always clear the error messages first.
  const newState = Object.assign({}, state, { errorMessage: '' });

  switch (action.type) {
    case REQUEST:
      const { login } = action;
      return Object.assign({}, newState, {
        isRequesting: true,
        login,
      });
    case RECEIVED:
      const { userInfo } = action;
      return Object.assign({}, newState, {
        isRequesting: false,
        userInfo
      });
    case ERROR:
      const { errorMessage } = action;
      return Object.assign({}, newState, {
        isRequesting: false,
        errorMessage,
      });
    case CLEAR_ERROR:
      return newState;
    case RESET:
      return initialState;
    case FINISHED:
      return Object.assign({}, newState, { isRequesting: false });
    case LOGIN_VIEW:
    case LOGIN_RECEIVED_GA4:
    case LOGIN_CHECKOUT_PROGRESS:
    default:
      return state;
  }
}

export function loginPageview() {
  return {
    type: LOGIN_VIEW,
  };
}

export function loginCheckoutProgress() {
  return {
    type: LOGIN_CHECKOUT_PROGRESS,
  };
}

export function requestLogin(login) {
  return { type: REQUEST, login };
}

export function receiveLogin(userInfo) {
  return { type: RECEIVED, userInfo };
}
export function receiveLoginGA4() {
  return { type: LOGIN_RECEIVED_GA4 };
}

export function error(errorMessage) {
  return { type: ERROR, errorMessage };
}

export function clearError() {
  return { type: CLEAR_ERROR };
}

export function trackError(errorMessage) {
  return { type: TRACK_ERROR, errorMessage };
}

export function trackSubmit() {
  return { type: TRACK_SUBMIT };
}

export function reset() {
  return { type: RESET };
}

export function finish() {
  return { type: FINISHED };
}

export function postLogin(login, password, channel, token, fingerprint) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { login: loginState = {} } = getState();

      if (loginState.isRequesting) {
        return reject();
      }

      dispatch(requestLogin(login));

      return CustomerApi.postLogin(login, password, channel, token, fingerprint)
        .then(userInfo => {
          dispatch(receiveLogin(userInfo));
          dispatch(receiveLoginGA4());
          return resolve(userInfo);
        })
        .catch((err) => {
          const { status, response } = err || {};

          if (status === 403) {
            dispatch(fail(customerMessages.inactiveLogin, status));
            dispatch(trackError(customerMessages.inactiveLogin));
            dispatch(finish());
          } else if (status === 401 && response?.body?.error_message === 'Invalid register') {
            dispatch(error(customerMessages.customerBlocked));
            dispatch(trackError(customerMessages.customerBlocked));
          } else if ((status === 422) || (status === 401)) {
            dispatch(error(customerMessages.invalidLoginOrPassword));
            dispatch(trackError(customerMessages.invalidLoginOrPassword));
          } else {
            dispatch(fail(customerMessages.loginFail, status));
            dispatch(trackError(customerMessages.loginFail));
            dispatch(finish());
          }

          dispatch(metricsError('login', err.response?.body.error_message));

          return reject(err);
        });
    });
  };
}
