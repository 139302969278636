import { scroller, animateScroll } from 'react-scroll';

const defaultOptions = {
  duration: 1000,
  smooth: true,
  offset: 0
};

export function scrollToTop() {
  animateScroll.scrollToTop();
}

export function scrollToElement(elementName, options = {}) {
  const scrollOptions = Object.assign({}, defaultOptions, options);

  setTimeout(() => {
    scroller.scrollTo(elementName, scrollOptions);
  }, 100);
}

let newAnimationId = 0;

export function animateScrollTo(el, to, duration) {
  const init = el.scrollTop;
  const startTime = performance.now();

  let direction = to < init;
  let change = 0;

  if (direction) {
    change = init - to;
  } else {
    change = to - init;
  }

  function animate(animationId) {
    if (animationId < newAnimationId) {
      return;
    }

    const elapsed = (performance.now() - startTime);
    const percentTime = elapsed / duration;

    let newTop = direction
      ? init - (change * percentTime)
      : init + (change * percentTime);

    if ((!direction && (newTop >= to))
      || (direction && (newTop <= to))) {
      return;
    }

    window.requestAnimationFrame(function animationStep() {
      el.scrollTop = newTop;
      animate(animationId);
    });
  }

  animate(++newAnimationId);
}

export function scrollToError() {
  const firstErroredElement = document
    .querySelector('input[class$="--withError"]');

  if (firstErroredElement) {
    scrollToElement(
      firstErroredElement.getAttribute('name'),
      { offset: -20 }
    );
  }
}

export function getScrollBottomPosition() {
  return window.innerHeight + document.documentElement.scrollTop;
}

export function getElementPosition(selector) {
  return document.querySelector(selector)?.offsetTop;
}
