import {
  checkoutProgress,
  DEFAULT_PAGE_TYPE_CATEGORY,
} from './checkoutProgress';
import { pageviewLayer } from 'utils/data-layer/pageviewLayer';

export const paymentPageview = (action, stateBefore, stateAfter) =>
  pageviewLayer(stateAfter, '4');

export const paymentCheckoutProgress = (action, stateBefore, stateAfter) =>
  checkoutProgress({
    pageType: DEFAULT_PAGE_TYPE_CATEGORY.review,
    stateAfter,
  });

export function paymentShippingCalcImpression(action, stateBefore) {
  return {
    event: 'shipping',
    action: 'impression',
    content_type: 'revisao:frete',
    basketId: stateBefore.basket.basket.id,
    deliveriesId:
      stateBefore.basket.shippingPackages.shippingTypes.deliveries.packages[0]
        .deliveryId,
    cep: stateBefore.basket.shippingPackages.shippingTypes.deliveries.address
      .zip_code,
  };
}
