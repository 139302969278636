import * as AddressApi from 'api/address-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';

export const REQUEST = 'address-list/REQUEST';
export const RECEIVED = 'address-list/RECEIVED';
export const FINISHED = 'address-list/FINISHED';

export const initialState = {
  isRequesting: false,
  addressList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED:
      const { addressList } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        addressList
      });
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestAddressList() {
  return { type: REQUEST };
}

export function receiveAddressList(addressList) {
  return { type: RECEIVED, addressList };
}

export function finish() {
  return { type: FINISHED };
}

export function fetchAddressList({ showAll }) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { addressList = {} } = getState();

    if (addressList.isRequesting) {
      return reject(new Error('Already fetching address list.'));
    }

    dispatch(requestAddressList());

    return AddressApi.getAddressList(showAll)
      .then(res => {
        dispatch(receiveAddressList(res));
        return resolve(res);
      }, err => {
        const { status } = err;

        if (status === 401) {
          dispatch(unauthorizedLogout(err));
          dispatch(fail(
            customerMessages.expired,
            status
          ));
        } else if (status === 406) {
          dispatch(fail(
            customerMessages.incompleteRegister,
            status
          ));
        } else if (status !== 400 && status !== 404) {
          dispatch(fail(
            customerMessages.failFetchAddressList,
            status,
            'Error on thunk when fetching address list'
          ));
        }
        dispatch(finish());
        return reject(err);
      });
  });
}
