import { addToDatalayer } from 'utils/gtm';

import * as basketActions from 'reducers/basket';
import * as orderAction from 'reducers/order';
import * as socialLoginAction from 'reducers/social-login';
import * as bobbyAction from 'reducers/bobby';
import * as shipmentAction from 'reducers/shipment';
import * as addressListAction from 'reducers/address-list';
import * as signupAction from 'reducers/signup';
import * as loginActions from 'reducers/login';
import * as promocodeActions from 'reducers/promocode';
import * as metricsActions from 'reducers/metrics';

const sendGTMEvent = (action) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  switch (action.type) {
    case basketActions.RECEIVED:
    case basketActions.RECEIVED_IMPRESSION:
    case basketActions.REMOVE_ITEM:
    case bobbyAction.ADD_TO_CART:
    case bobbyAction.RECEIVED_CART_DETAILS:
    case bobbyAction.RECEIVED_TRENDS:
    case loginActions.RECEIVED:
    case orderAction.CHANGE_PAYMENT_TYPE:
    case orderAction.RECEIVED:
    case orderAction.PAYMENT_TYPE:
    case orderAction.COPY_PIX_CLICK:
    case promocodeActions.SET_PROMOCODE:
    case shipmentAction.REQUEST:
    case shipmentAction.RECEIVED:
    case shipmentAction.TRACK:
    case signupAction.RECEIVED:
    case socialLoginAction.REQUEST_PROFILE:
    case metricsActions.ERROR:
      addToDatalayer(action, window.dataLayer);
      return;
    case addressListAction.RECEIVED:
      if (!action.addressList[0].redirect) {
        addToDatalayer(action, window.dataLayer);
      }
      return;
    default:
      return;
  }
};

const gtmMiddleware = () => (next) => (action) => {
  const result = next(action);
  sendGTMEvent(action);
  return result;
};

export default gtmMiddleware;
