import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import { Element } from 'react-scroll';

import AddressForm from 'components/Address/AddressForm/AddressForm';
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';
import * as customerMessages from 'constants/customer-messages';
import * as addressActions from 'reducers/address';
import * as cleaners from 'utils/value-cleaners';
import * as validators from 'utils/validators';
import { scrollToElement } from 'utils/scroll';

const { string, object, func, bool } = PropTypes;

const mapStateToProps = ({ form, address }) => ({ form, address });
const mapDispatchToActions = { ...addressActions, changeField: change };

export const formFields = [
  'zipcode',
  'address',
  'number',
  'complement',
  'neighbourhood',
  'city',
  'state',
  'reference',
  'telephone',
  'receiver',
  'isDefault',
];

export const handleZipcodeSubmit = (
  zipcodeValue,
  formName,
  fetchAddress,
  changeField
) => {
  return () => {
    if (!zipcodeValue) {
      return Promise.resolve();
    }

    return fetchAddress(cleaners.zipcode(zipcodeValue))
      .then((address) => {
        Object.keys(address).forEach((key) =>
          changeField(formName, key, address[key])
        );
      })
      .then(() => {
        if (formName === 'newAddress') {
          scrollToElement(formName);
        }
      });
  };
};

export const handleZipcodeChange = (resetAddress, fields) => {
  return (event) => {
    const fieldsToUpdate = [
      'address',
      'neighbourhood',
      'city',
      'state',
      'reference',
      'number',
      'complement',
      'telephone',
    ];

    event.preventDefault();

    resetAddress();
    fieldsToUpdate.forEach((key) => fields[key]?.onChange(''));
  };
};

export const errorsAddressForm = ({
  zipcode,
  address,
  receiver,
  telephone,
  number,
  neighbourhood,
  city,
  state,
  reference,
}) => {
  const errors = {};

  if (!zipcode || !validators.zipcode(cleaners.zipcode(zipcode))) {
    errors.zipcode = customerMessages.invalidZipcode;
  }

  if (!address) {
    errors.address = customerMessages.invalidAddress;
  }

  if (!receiver || !validators.fullName(receiver)) {
    errors.receiver = customerMessages.invalidReceiver;
  }

  if (validators.isNumber(receiver)) {
    errors.receiver = customerMessages.nameWithNumbers;
  }

  if (!telephone || !validators.mobilePhone(cleaners.onlyNumbers(telephone))) {
    errors.telephone = customerMessages.invalidTelephone;
  }

  if (!number) {
    errors.number = customerMessages.invalidNumber;
  }

  if (!neighbourhood) {
    errors.neighbourhood = customerMessages.invalidNeighbourhood;
  }

  if (!city) {
    errors.city = customerMessages.invalidCity;
  }

  if (!state) {
    errors.state = customerMessages.invalidState;
  }

  if (!reference || reference.trim().length < 5) {
    errors.reference = customerMessages.invalidReference;
  }

  return errors;
};

export default function addressFormContainerFactory(formName) {
  // We don't have a reduxForm decorator here, because we use it on different
  // places. In those places we call it with a different configs and name.
  class AddressFormContainer extends Component {
    static displayName = `AddressFormContainer-${formName}`;

    static propTypes = {
      form: object.isRequired,
      address: object.isRequired,
      fields: object.isRequired,
      fetchAddress: func.isRequired,
      resetAddress: func.isRequired,
      handleSubmit: func.isRequired,
      className: string.isRequired,
      closedLabelText: string,
      closedCancelComponent: object,
      openCancelComponent: object,
      submit: func.isRequired,
      canChangeZipcode: bool,
      dismissEditAddress: func.isRequired,
      changeField: func.isRequired,
    };

    handleSelfZipcodeSubmit = () => {
      const { fields, fetchAddress, changeField } = this.props;

      return handleZipcodeSubmit(
        fields.zipcode.value,
        formName,
        fetchAddress,
        changeField
      );
    };

    handleZipcodeSubmitClick = () => {
      const { dismissEditAddress } = this.props;

      return this.handleSelfZipcodeSubmit()().then(dismissEditAddress);
    };

    render() {
      const {
        fields,
        address: { address, neighbourhood, city, state },
        resetAddress,
        handleSubmit,
        className,
        closedLabelText,
        closedCancelComponent,
        openCancelComponent,
        submit,
        canChangeZipcode,
      } = this.props;
      const finalFields = Object.assign({}, fields, {
        address: Object.assign({}, fields.address, { disabled: !!address }),
        neighbourhood: Object.assign({}, fields.neighbourhood, {
          disabled: !!neighbourhood,
        }),
        city: Object.assign({}, fields.city, { disabled: !!city }),
        state: Object.assign({}, fields.state, { disabled: !!state }),
      });

      let continueClassName = 'continueButton';

      let gaObj =
        '{"category": "Novo endereco", "action": "Continuar", "label": "Clique"}';

      if (formName === 'editAddress') {
        gaObj =
          '{"category": "Endereco", "action": "Novo endereco", "label": "Salvar"}';
      }

      if (openCancelComponent) {
        continueClassName = `${continueClassName}--withCancel`;
      }

      const formClassname = `${className} AddressFormContainer`;

      return (
        <form
          method="post"
          className={formClassname}
          onSubmit={handleSubmit((values) => {
            return new Promise((resolve, reject) => {
              // If we do not have value for state we are on closed form.
              // If we are on closed form, submit should only fetch address.
              if (!finalFields.state.value) {
                this.handleSelfZipcodeSubmit()().then(resolve, reject);
              } else {
                const cleanValues = Object.assign({}, values, {
                  telephone: cleaners.onlyNumbers(values.telephone),
                  zipcode: cleaners.zipcode(values.zipcode),
                });

                const errors = Object.assign(
                  {},
                  errorsAddressForm(cleanValues)
                );

                if (!isEmpty(errors)) {
                  return reject(errors);
                }

                submit(cleanValues).then(resolve, reject);
              }
            });
          })}
        >
          <Element name={formName}>
            <AddressForm
              {...finalFields}
              closedLabelText={closedLabelText}
              title=""
              formName={formName}
              handleZipcodeSubmit={this.handleZipcodeSubmitClick}
              handleZipcodeChange={handleZipcodeChange(resetAddress, fields)}
              cancelComponent={closedCancelComponent}
              canChangeZipcode={canChangeZipcode}
            >
              <CheckboxGroup
                labelText="Definir este endereço como padrão para as próximas compras"
                field={fields.isDefault}
              />
              <div className="AddressForm-form-group-cancel">
                <div className="AddressForm-form-label-cancel">
                  {openCancelComponent}
                </div>
                <button className={continueClassName} data-ga={gaObj}>
                  Salvar e entregar aqui
                </button>
              </div>
            </AddressForm>
          </Element>
        </form>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToActions)(AddressFormContainer);
}
