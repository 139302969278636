import MobileModal from 'components/MobileModal/MobileModal';
import Responsive from 'components/Responsive';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize, reduxForm, reset } from 'redux-form';

import AddressBox from 'components/Address/AddressBox/AddressBox';
import * as addressActions from 'reducers/address';
import * as addressListActions from 'reducers/address-list';
import * as basketActions from 'reducers/basket';
import { scrollToElement } from 'utils/scroll';
import addressFormContainerFactory, {
  errorsAddressForm, formFields
} from './AddressFormContainer';

const mapStateToProps = ({
  address,
  addressList,
  channel
}) => ({
  ...address,
  ...addressList,
  showEditAddress: channel.configs.show_edit_address ?? false,
});
const mapDispatchToActions = {
  initialize,
  reset,
  ...basketActions,
  ...addressActions,
  ...addressListActions,
};

const editFormName = 'editAddress';
const EditAddressFormContainer = reduxForm({
  form: editFormName,
  fields: formFields,
  destroyOnUnmount: false,
  validate: errorsAddressForm
})(addressFormContainerFactory(editFormName));

@connect(mapStateToProps, mapDispatchToActions)
class AddressListContainer extends Component {
  static propTypes = {
    addressList: PropTypes.array.isRequired,
    editUuid: PropTypes.string,
    isRequesting: PropTypes.bool.isRequired,
    patchAddress: PropTypes.func.isRequired,
    editAddress: PropTypes.func.isRequired,
    dismissEditAddress: PropTypes.func.isRequired,
    setAddress: PropTypes.func.isRequired,
    putAddress: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    redirectOnSuccess: PropTypes.func.isRequired,
    redirectOnError: PropTypes.func.isRequired,
    redirectToEditRegistration: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    showEditAddress: PropTypes.bool.isRequired,
  };

  render() {
    const {
      isRequesting,
      patchAddress,
      addressList,
      editAddress,
      dismissEditAddress,
      setAddress,
      putAddress,
      fetchAddress,
      initialize: initializeForm,
      reset: resetForm,
      editUuid = '',
      redirectOnSuccess,
      redirectOnError,
      redirectToEditRegistration,
      isMobile,
      showEditAddress,
    } = this.props;

    if (isRequesting || (addressList.length && addressList[0].redirect)) {
      return false;
    }

    return (
      <div>
        {addressList.map((addressObj) => {
          const {
            zipcode,
            telephone,
            receiver,
            number: addressNumber,
            complement,
            reference,
            isDefault,
            addressUuid
          } = addressObj;

          return editUuid !== addressUuid && (
            <AddressBox
              {...addressObj}
              key={addressUuid}
              showEditAddress={showEditAddress}
              handleChooseClick={function handleChooseClick(e) {
                e.preventDefault();
                return patchAddress(addressUuid)
                  .then(() => setAddress(addressObj))
                  .then(redirectOnSuccess).catch((err) => {
                    if (err.status === 400) {
                      return window.location.reload();
                    } else if (err.status === 409) {
                      return redirectOnError();
                    } else if (err.status === 406) {
                      return redirectToEditRegistration();
                    }
                    throw err;
                  });
              }}
              handleEditClick={function handleEditClick(e) {
                e.preventDefault();
                return fetchAddress(zipcode)
                  .then((res) => {
                    const { address, neighbourhood, city, state } = res;
                    initializeForm(editFormName, {
                      zipcode,
                      address,
                      neighbourhood,
                      city,
                      state,
                      reference,
                      receiver,
                      telephone,
                      number: addressNumber,
                      complement,
                      isDefault
                    }, formFields);
                    editAddress(addressUuid);
                    if (!isMobile) {
                      scrollToElement(editFormName);
                    }
                    resetForm('newAddress');
                  });
              }}
            />
          ) || (
            <MobileModal
              titleClass="AddressModal-header"
              handleClose={(e) => {
                e.preventDefault();
                dismissEditAddress();
                resetForm(editFormName);
              }}
              title="Editar endereço"
            >
              <EditAddressFormContainer
                key={addressUuid}
                className="AddressBox"
                closedLabelText="CEP"
                canChangeZipcode={false}
                closedCancelComponent={(
                  <a
                    href="#"
                    onClick={function handleClosedCancelClick(e) {
                      e.preventDefault();
                      dismissEditAddress();
                      resetForm(editFormName);
                    }}
                  >Cancelar</a>
                )}
                openCancelComponent={(!this.props.isMobile &&
                  <a
                    href="#"
                    onClick={function handleOpenCancelClick(e) {
                      e.preventDefault();
                      dismissEditAddress();
                      resetForm(editFormName);
                    }}
                  >Cancelar</a>
                )}
                submit={function onSubmit(values) {
                  return new Promise((resolve) => {
                    putAddress(Object.assign({}, values, {
                      addressUuid
                    })).then(() => addressUuid)
                      .then(patchAddress)
                      .then(redirectOnSuccess)
                      .then(() => resolve())
                      .catch((err) => {
                        if (err.status === 409) {
                          return redirectOnError();
                        } else if (err.status === 406) {
                          return redirectToEditRegistration();
                        }
                        throw err;
                      }
                      );
                  });
                }}
              />
            </MobileModal>
          );
        })}
      </div>
    );
  }
}

export default Responsive(AddressListContainer);
