import isEmpty from 'lodash/isEmpty';

import * as customerMessages from 'constants/customer-messages';
import * as PaymentApi from 'api/payment-api';
import * as PaymentModel from 'api/models/payment-models';
import { adsCollectorPlaceOrder } from 'utils/magalu-ads-collector/magalu-ads-collector';

import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';
import { metricsError } from './metrics';
import { findInternationalProductCurrency } from 'utils/currency';

export const REQUEST = 'order/REQUEST';
export const RECEIVED = 'order/RECEIVED';
export const REQUEST_PLACE_ORDER_GA4 = 'order/REQUEST-PLACE-ORDER-GA4';
export const REQUEST_PLACE_ORDER = 'order/REQUEST-PLACE-ORDER';
export const RECEIVED_PLACE_ORDER = 'order/RECEIVED-PLACE-ORDER';
export const RECEIVED_PLACE_ORDER_GA4 = 'order/RECEIVED-PLACE-ORDER-GA4';
export const CHANGE_CLICKED_FLAG = 'order/CHANGE-CLICKED-FLAG';
export const CHANGE_PAYMENT_TYPE = 'order/CHANGE-PAYMENT-TYPE';
export const AUTO_CHANGE_PAYMENT_TYPE = 'order/AUTO-CHANGE-PAYMENT-TYPE';
export const RESET_CURRENT_TYPE = 'order/RESET-CURRENT-TYPE';
export const CLEAR = 'order/CLEAR';
export const FINISHED = 'order/FINISHED';
export const NEW_ORDER_ATTEMPT = 'order/NEW-ORDER-ATTEMPT';
export const RESET_ORDER_ATTEMPTS = 'order/RESET-ORDER-ATTEMPTS';
export const REQUEST_CALL_ME = 'order/REQUEST-CALL-ME';
export const RECEIVED_CALL_ME = 'order/RECEIVED-CALL-ME';
export const PAYMENT_TYPE = 'order/PAYMENT_TYPE';
export const SET_CURRENCY = 'order/SET_CURRENCY';
export const PAYMENT_CHECKOUT_PROGRESS = 'order/PAYMENT_CHECKOUT_PROGRESS';
export const PAYMENT_SHIPPING_CALC_IMPRESSION =
  'order/PAYMENT_SHIPPING_CALC_IMPRESSION';
export const ORDER_VIEW = 'order/ORDER_VIEW';
export const COPY_PIX_CLICK = 'order/COPY_PIX_CLICK';
export const ITEM_OUT_OF_STOCK = 'order/ITEM_OUT_OF_STOCK';
export const ITEM_NOT_ALLOWED_FOR_COMPANY =
  'order/ITEM_NOT_ALLOWED_FOR_COMPANY';
export const SET_VIOLATION_ORDER = 'order/SET_VIOLATION_ORDER';
export const UPDATE_VIOLATED_ORDER = 'order/UPDATE_VIOLATED_ORDER';
export const PAYMENT_VIEW = 'order/PAYMENT_VIEW';
export const CUSTOMER_RESTRICTED_CPF = 'order/CUSTOMER_RESTRICTED_CPF';

export const initialState = {
  isRequesting: false,
  currentType: '',
  clickedFlag: '', // used in case flag is not detected automatically.
  orderId: '',
  attemptsCount: 1,
  paymentData: {},
  order: {},
  itemsOutOfStock: [],
  itemsNotAllowedForCompanies: [],
  orderViolatedPolicies: [],
  orderViolations: [],
  currency: null
};

const policiesStatus = {
  VIOLATED: 'violated',
  PASSED: 'passed',
};

const DEFAULT_PAYMENT_TYPE_MESSAGE = {
  pix: 'pix',
  new_card: 'cartao novo',
  bank_slip: 'boleto',
};

export default function reducer(state = initialState, action = {}) {
  const { orderId, paymentData, currentType, order, clickedFlag, currency } = action;

  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        isRequesting: true,
        orderId,
      });
    case REQUEST_PLACE_ORDER:
      return Object.assign({}, state, {
        isRequesting: true,
        paymentData,
      });
    case REQUEST_PLACE_ORDER_GA4:
      return Object.assign({}, state, {
        isRequesting: true,
        paymentData,
      });
    case RECEIVED:
    case RECEIVED_PLACE_ORDER:
      return Object.assign({}, state, {
        isRequesting: false,
        order,
      });
    case RECEIVED_PLACE_ORDER_GA4:
      return Object.assign({}, state, {
        isRequesting: false,
        order,
      });
    case REQUEST_CALL_ME:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED_CALL_ME:
      return Object.assign({}, state, { isRequesting: false });
    case CHANGE_PAYMENT_TYPE:
      return Object.assign({}, state, { currentType });
    case AUTO_CHANGE_PAYMENT_TYPE:
      return Object.assign({}, state, { currentType });
    case CHANGE_CLICKED_FLAG:
      return Object.assign({}, state, { clickedFlag });
    case ITEM_OUT_OF_STOCK:
      return Object.assign({}, state, {
        itemsOutOfStock: [{ sku: action.sku }, ...state.itemsOutOfStock],
      });
    case ITEM_NOT_ALLOWED_FOR_COMPANY:
      return Object.assign({}, state, {
        itemsNotAllowedForCompanies: [
          { sku: action.sku },
          ...state.itemsNotAllowedForCompanies,
        ],
      });
    case RESET_CURRENT_TYPE:
      return Object.assign({}, state, { currentType: '' });
    case NEW_ORDER_ATTEMPT:
      return Object.assign({}, state, {
        attemptsCount: state.attemptsCount + 1,
      });
    case RESET_ORDER_ATTEMPTS:
      return Object.assign({}, state, {
        attemptsCount: initialState.attemptsCount,
      });
    case CLEAR:
      return Object.assign({}, initialState);
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    case PAYMENT_CHECKOUT_PROGRESS:
    case PAYMENT_SHIPPING_CALC_IMPRESSION:
    case PAYMENT_VIEW:
    case ORDER_VIEW:
    case SET_VIOLATION_ORDER:
      return Object.assign({}, state, {
        orderViolatedPolicies: action.violations,
        orderViolations: action.violations,
      });
    case UPDATE_VIOLATED_ORDER:
      return Object.assign({}, state, {
        orderViolatedPolicies: action.violations,
      });
    case SET_CURRENCY:
      return Object.assign({}, state, { currency });
    default:
      return state;
  }
}

export function setOrderCurrency(currency) {
  return {
    type: SET_CURRENCY,
    currency,
  };
}

export function paymentPageview() {
  return {
    type: PAYMENT_VIEW,
  };
}

export function paymentCheckoutProgress() {
  return {
    type: PAYMENT_CHECKOUT_PROGRESS,
  };
}

export function paymentShippingCalcImpression() {
  return {
    type: PAYMENT_SHIPPING_CALC_IMPRESSION,
  };
}

export function orderPageview() {
  return {
    type: ORDER_VIEW,
  };
}

export function copyPixClick() {
  return {
    type: COPY_PIX_CLICK,
  };
}

export function requestOrder(orderId) {
  return { type: REQUEST, orderId };
}

export function receivedOrder(order) {
  return { type: RECEIVED, order };
}

export function requestPlaceOrder(paymentData) {
  return { type: REQUEST_PLACE_ORDER, paymentData };
}

export function requestPlaceOrderGA4(paymentData) {
  return { type: REQUEST_PLACE_ORDER_GA4, paymentData };
}

export function receivedPlaceOrderGA4(order) {
  return { type: RECEIVED_PLACE_ORDER_GA4, order };
}

export function receivedPlaceOrder(order) {
  return { type: RECEIVED_PLACE_ORDER, order };
}

export function changePaymentType(currentType) {
  return { type: CHANGE_PAYMENT_TYPE, currentType };
}

export function autoChangePaymentType(currentType) {
  return { type: AUTO_CHANGE_PAYMENT_TYPE, currentType };
}

export function changeClickedFlag(clickedFlag) {
  return { type: CHANGE_CLICKED_FLAG, clickedFlag };
}

export function newPlaceOrderAttempt() {
  return { type: NEW_ORDER_ATTEMPT };
}

export function resetOrderAttempts() {
  return { type: RESET_ORDER_ATTEMPTS };
}

export function resetCurrentType() {
  return { type: RESET_CURRENT_TYPE };
}

export function clear() {
  return { type: CLEAR };
}

export function finish() {
  return { type: FINISHED };
}

export function requestCallMe() {
  return { type: REQUEST_CALL_ME };
}

export function receivedCallMe() {
  return { type: RECEIVED_CALL_ME };
}

export function setOrderViolations(violations) {
  return {
    type: SET_VIOLATION_ORDER,
    violations,
  };
}

export function updateOrderViolations(violations) {
  return {
    type: UPDATE_VIOLATED_ORDER,
    violations,
  };
}

export function paymentType(paymentData) {
  return {
    type: PAYMENT_TYPE,
    paymentData,
  };
}

export function itemOutOfStock(sku) {
  return {
    type: ITEM_OUT_OF_STOCK,
    sku,
  };
}

export function itemNotAllowedForCompany(sku) {
  return {
    type: ITEM_NOT_ALLOWED_FOR_COMPANY,
    sku,
  };
}

export function postCallMe() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { order: orderState = {} } = getState();

      if (orderState.isRequesting) {
        return reject('Already fetching call me.');
      }

      dispatch(requestCallMe());

      return PaymentApi.postCallMe().then(
        (res) => {
          dispatch(receivedCallMe());
          return resolve(res);
        },
        (err) => {
          dispatch(finish());
          return reject(err);
        }
      );
    });
}

const formatViolationRule = (rule) => {
  return {
    ruleDescription: rule.rule_description,
    skus: rule.detail.reduce(
      (acc, detail) => ({ ...acc, [detail.sku]: detail }),
      {}
    ),
  };
};

const formatPolicies = (policy) => {
  return {
    description: policy.policy_description,
    violations: policy.violated_rules.map(formatViolationRule),
  };
};

function getOrderViolations(policies, redirectTo) {
  const violatedPolcies = policies.filter(
    (policy) => policy.status === policiesStatus.VIOLATED
  );
  if (!!violatedPolcies.length) {
    redirectTo();
    return setOrderViolations(violatedPolcies.map(formatPolicies));
  }
}

export function fetchOrder(orderId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { order: orderState = {} } = getState();

      if (orderState.isRequesting) {
        return reject('Already fetching order.');
      }

      dispatch(requestOrder(orderId));
      return PaymentApi.getOrder(orderId).then(
        (res) => {
          dispatch(receivedOrder(res));
          return resolve(res);
        },
        (err) => {
          const { status } = err;

          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            dispatch(fail(customerMessages.expired, status));
          } else if (status === 404) {
            dispatch(fail(customerMessages.failOrderNotFound, status));
          } else {
            dispatch(fail(customerMessages.failFetchOrder, status));
          }
          dispatch(finish());
          return reject(err);
        }
      );
    });
}

function handlePostPlaceOrderResponse(response, redirectToBasket, dispatch) {
  const action = !!response.policies
    ? getOrderViolations(response.policies, redirectToBasket)
    : receivedPlaceOrderGA4(PaymentModel.placeOrder(response));

  dispatch(action);
}

export function postPlaceOrder(
  paymentData,
  orderType = 'savedCard',
  redirectToAddress,
  redirectToBasket
) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { order: orderState = {}, basket: basketState = {} } = getState();
      const packages = basketState?.shippingPackages?.shippingTypes?.deliveries?.packages;
      const currency = findInternationalProductCurrency(packages);
      if (currency) {
        Object.assign(paymentData, { currency });
        dispatch(setOrderCurrency(currency));
      }
      paymentData.items = paymentData.items.map(PaymentModel.paymentItem);
      if (orderState.isRequesting) {
        return reject('Already requesting order placement.');
      }

      dispatch(newPlaceOrderAttempt());
      dispatch(requestPlaceOrder(paymentData));

      return PaymentApi.postPlaceOrder(paymentData).then(
        (res) => {
          dispatch(paymentType(paymentData));

          adsCollectorPlaceOrder(
            res,
            basketState.basket?.customerId,
            basketState.shippingPackages?.shippingTypes?.deliveries?.address?.zip_code
          );

          handlePostPlaceOrderResponse(res, redirectToBasket, dispatch);

          return resolve(res);
        },
        (err) => {
          const { status } = err;
          let errorMessage = '';
          let errorCode = '';

          if (
            err.response &&
            err.response.body &&
            (err.response.body.error_message || err.response.body.error_code)
          ) {
            errorMessage = err.response.body.error_message ?? err.response.body.message;
            errorCode = err.response.body?.error_message?.error_code ?? err.response.body?.error_code;
          }

          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            dispatch(fail(customerMessages.expired, err.status));
          } else if (status !== 409) {
            const message =
              DEFAULT_PAYMENT_TYPE_MESSAGE[orderType] | 'cartao salvo';

            dispatch(metricsError(message, errorMessage));

            if (orderState.attemptsCount >= 1) {
              dispatch(resetOrderAttempts());
              dispatch(finish());
              return reject({ status: 402 });
            }
            dispatch(fail(customerMessages.failPlaceOrder, status));
          } else if (
            status === 409 &&
            errorMessage.error_message === 'Items out of stock'
          ) {
            errorMessage.error_detail.items_out_of_stock.map((item) => {
              dispatch(itemOutOfStock(item.item.sku));
            });
            redirectToBasket();
          } else if (
            status === 409 &&
            errorCode === 'not_allowed_items_for_company'
          ) {
            errorMessage.error_detail.items_not_allowed.map((item) => {
              dispatch(itemNotAllowedForCompany(item.sku));
            });
            redirectToBasket();
          } else if (
            status === 409 &&
            (
              errorCode === 'customer_with_restricted_cpf' ||
              errorCode === 'order_without_calculated_taxes'
            )
          ) {
            err.errorCode = errorCode;
          } else if (status === 409) {
            redirectToAddress();
          }

          dispatch(finish());
          return reject(err);
        }
      );
    });
}

export function updateViolationOrderByQuantity(items, orderViolations) {
  let policies = orderViolations;

  policies = policies.map((policy) => {
    return {
      ...policy,
      violations: policy.violations.map((violation) => ({
        ...violation,
        skus: getViolatedSkusByItems(violation, items),
      })),
    };
  });

  return updateOrderViolations(
    policies.filter(
      (policy) =>
        policy.violations.filter((violation) => !isEmpty(violation.skus)).length
    )
  );
}

function isViolatedItemQtyGreaterItemQty(items, sku, available) {
  const { quantity = 0 } =
    items.find(({ id }) => String(id) === String(sku)) || {};
  return Number(quantity) > Number(available);
}

function getViolatedSkusByItems(violation, items) {
  const violationEntries = Object.entries(violation.skus).filter(
    ([sku, { available }]) =>
      isViolatedItemQtyGreaterItemQty(items, sku, available)
  );

  return Object.fromEntries(violationEntries);
}
