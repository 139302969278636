import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routeReducer as routing } from 'redux-simple-router';

import * as maskers from 'utils/maskers';

import basket from './basket';
import channel from './channel';
import customerArea from './customer-area';
import shipment from './shipment';
import login from './login';
import socialLogin from './social-login';
import signupAvailable from './signup-available';
import signup from './signup';
import customer from './customer';
import address from './address';
import addressList from './address-list';
import cardList from './card-list';
import allowedBins from './allowed-bins';
import installments from './installments';
import order from './order';
import bobby from './bobby';
import unauthorized from './unauthorized';
import fail from './fail';
import loading from './loading';
import giftCard from './gift-card';
import promocode from './promocode';
import captcha from './captcha';
import recommendation from './recommendation';
import sendOtp from './send-otp';
import validateOtp from './validate-otp';
import tmx from './tmx';
import navigation from './navigation';
import failOtp from './fail-otp';

const addressMaskers = {
  zipcode: maskers.zipcode,
  address: maskers.addressInput,
  number: maskers.addressNumber,
  complement: maskers.addressComplement,
  neighbourhood: maskers.addressNeighbourhood,
  city: maskers.addressCity,
  state: maskers.addressState,
  reference: maskers.addressReference,
};

const pfMaskers = {
  cpf: maskers.cpf,
  telephone: maskers.mobilePhone,
  birthDate: maskers.birthDate,
  fullName: maskers.fullName,
};

const pfAddressMaskers = {
  ...pfMaskers,
  ...addressMaskers,
};

const pjMaskers = {
  cnpj: maskers.cnpj,
  homePhone: maskers.telephone,
  comercialPhone: maskers.telephone,
  mobilePhone: maskers.mobilePhone,
  birthDate: maskers.birthDate,
  fullName: maskers.fullName,
  companyName: maskers.companyName,
  fantasyName: maskers.fantasyName,
  stateRegistration: maskers.stateRegistration,
  ...addressMaskers,
};

const addressFormMaskers = {
  ...addressMaskers,
  telephone: maskers.mobilePhone,
  receiver: maskers.addressReceiver,
};

export default combineReducers({
  form: form.normalize({
    zipcode: {
      zipcode: maskers.zipcode
    },
    signupPF: pfAddressMaskers,
    editPF: pfAddressMaskers,
    customerAreaChangeData: { telephone: maskers.mobilePhone, telephoneConfirm: maskers.mobilePhone },
    signupPJ: pjMaskers,
    editPJ: pjMaskers,
    newAddress: addressFormMaskers,
    editAddress: addressFormMaskers,
    savedCard: {
      CVC: maskers.cvc
    },
    newCard: {
      CVC: maskers.cvc,
      number: maskers.cardNumber,
      fullName: maskers.fullNameOnlyCharacters
    },
    virtualDebitElo: {
      CVC: maskers.cvc,
      number: maskers.cardNumber,
      fullName: maskers.fullNameOnlyCharacters
    },
    giftCard: {
      from: maskers.fullName,
      to: maskers.fullName,
      cpfOrCnpj: maskers.cpfOrCnpj,
      message: maskers.giftCardMessage,
      stateRegistration: maskers.stateRegistration
    },
  }),
  routing,
  channel,
  customerArea,
  basket,
  shipment,
  login,
  socialLogin,
  signupAvailable,
  signup,
  customer,
  address,
  addressList,
  installments,
  cardList,
  allowedBins,
  order,
  bobby,
  unauthorized,
  fail,
  loading,
  giftCard,
  promocode,
  captcha,
  recommendation,
  sendOtp,
  validateOtp,
  tmx,
  navigation,
  failOtp,
});
