import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, reset, change } from 'redux-form';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import * as routeNames from 'constants/route-names';

import { scrollToError } from 'utils/scroll';
import { redirectToLogin, redirectToNextUrl } from 'utils/redirect';
import { moveCursorToLastChar } from 'utils/moveCursorToLastChar';
import { isLogged } from 'utils/session';

import AddressListContainer from 'containers/AddressListContainer';
import addressFormContainerFactory, {
  formFields,
  handleZipcodeSubmit,
  errorsAddressForm
} from 'containers/AddressFormContainer';

import AddressModalInfo from 'components/Address/AddressModalInfo/AddressModalInfo';

import * as unauthorizedActions from 'reducers/unauthorized';
import * as addressActions from 'reducers/address';
import * as shipmentActions from 'reducers/shipment';
import * as addressListActions from 'reducers/address-list';
import * as basketActions from 'reducers/basket';
import * as loadingActions from 'reducers/loading';

import './AddressPage.scss';

const addressListLoadingID = 'addressListLoading';
const addressLoadingID = 'addressLoading';
const basketLoadingID = 'basketLoading';

const formName = 'newAddress';
const NewAddressFormContainer = reduxForm({
  form: formName,
  fields: formFields,
  validate: errorsAddressForm,
  onSubmitFail: () => {
    setTimeout(scrollToError, 100);
  }
})(addressFormContainerFactory(formName));

const redirectToBasket = (router) => router.push(routeNames.root);

@connect(({ form, basket, addressList, unauthorized, shipment, address }) => ({
  ...basket,
  ...addressList,
  ...unauthorized,
  ...shipment,
  addressListRequesting: addressList.isRequesting,
  addressRequesting: address.isRequesting,
  basketRequesting: basket.isRequesting,
  form
}), {
  ...basketActions,
  ...addressActions,
  ...addressListActions,
  ...unauthorizedActions,
  ...shipmentActions,
  ...loadingActions,
  resetForm: reset,
  changeField: change,
})

export default class AddressPage extends Component {
  static propTypes = {
    firstRequest: PropTypes.bool.isRequired,
    err: PropTypes.object,
    reset: PropTypes.func.isRequired,
    resetAddress: PropTypes.func.isRequired,
    putAddress: PropTypes.func.isRequired,
    patchAddress: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    addressList: PropTypes.array.isRequired,
    fetchAddressList: PropTypes.func.isRequired,
    shipment: PropTypes.object.isRequired,
    addressListRequesting: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    dismissEditAddress: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    fetchBasketForGTM: PropTypes.func.isRequired
  };

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  state = { willRedirectOnDefaultAddress: false };

  componentWillMount() {
    const {
      location,
      fetchAddressList,
      shipment,
      fetchAddress,
      changeField,
      fetchBasketForGTM,
    } = this.props;
    const { router } = this.context;

    fetchBasketForGTM();

    if (!isLogged()) {
      return redirectToLogin(router, routeNames.address, location);
    }

    return fetchAddressList({ showAll: location.query.showAll === 'true' })
      .then((res = []) => {
        if (shipment.zipcode) {
          const foundAddress = res.find(address => address.zipcode === shipment.zipcode);

          if (! foundAddress && ! isEmpty(res) && ! res[0].redirect) {
            return shipment.zipcode;
          }
        }
      })
      .then((zipcode) => {
        if (zipcode) {
          handleZipcodeSubmit(
            zipcode,
            formName,
            fetchAddress,
            changeField
          )();

          scrollToElement(formName);
        }
      })
      .catch(err => {
        if (err) {
          if (err.status === 400) {
            return redirectToBasket(router);
          } else if (err.status === 401) {
            return redirectToLogin(router, routeNames.address, location);
          } else if (err.status === 406) {
            router.push({
              pathname: routeNames.editRegistration,
              query: { next: routeNames.address }
            });
          }
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    const {
      err,
      reset: resetUnauthorized,
      location,
      addressList,
      addressListRequesting,
      addressRequesting,
      basketRequesting,
      toggleLoading
    } = nextProps;
    const { router } = this.context;
    if (err) {
      redirectToLogin(router, routeNames.address, location);
      return resetUnauthorized();
    }

    if (! this.state.willRedirectOnDefaultAddress &&
        location.query.showAll !== 'true' &&
        addressList.length === 1 &&
        addressList[0].redirect === true) {
      this.setState({ willRedirectOnDefaultAddress: true });
      return this.customRedirectToDelivery();
    }

    if (! isEqual(this.props, nextProps)) {
      toggleLoading(addressListLoadingID, addressListRequesting);
      toggleLoading(addressLoadingID, addressRequesting);
      toggleLoading(basketLoadingID, basketRequesting);
    }
  }

  componentWillUnmount() {
    const { toggleLoading, dismissEditAddress } = this.props;

    dismissEditAddress();

    toggleLoading(addressListLoadingID, false);
    toggleLoading(addressLoadingID, false);
    toggleLoading(basketLoadingID, false);
  }

  onNewAddressSubmit = (values) => {
    const { putAddress, patchAddress } = this.props;
    return new Promise((resolve) => {
      return putAddress(values)
        .then(({ addressUuid }) => addressUuid)
        .then(patchAddress)
        .then(this.customRedirectToDelivery)
        .then(resolve);
    });
  };

  customRedirectToDelivery = () => {
    const { location } = this.props;
    const { router } = this.context;
    const redirectParams = {
      tipo: location.query ? location.query.tipo : undefined,
      changedAddress: location.query.showAll === 'true' ? true : undefined,
    };

    return redirectToNextUrl(router.replace, {
      next: `/${routeNames.delivery}`,
      nextQuery: redirectParams,
    });
  };

  render() {
    const {
      resetAddress,
      addressListRequesting,
      resetForm,
      location
    } = this.props;
    const { willRedirectOnDefaultAddress } = this.state;
    const { router } = this.context;

    return (
      <span>
        <div className="AddressPage">
          {! addressListRequesting &&
          ! willRedirectOnDefaultAddress &&
            <div className="AddressPage-title">
              Endereço de entrega
            </div>
          }
          <AddressModalInfo location={location} />
          <AddressListContainer
            redirectOnSuccess={this.customRedirectToDelivery}
            redirectOnError={function redirectOnError() {
              redirectToBasket(router);
            }}
            redirectToEditRegistration={function redirectToEditRegistration() {
              router.push({
                pathname: routeNames.editRegistration,
                query: { next: routeNames.address }
              });
            }}
          />
          {! addressListRequesting && ! willRedirectOnDefaultAddress && (
            <NewAddressFormContainer
              className="NewAddress"
              closedLabelText="Cadastrar novo endereço"
              canChangeZipcode
              handleKeyUp={moveCursorToLastChar}
              openCancelComponent={<a href="#" onClick={function handleCancelClick(e) {
                e.preventDefault();
                resetAddress();
                resetForm(formName);
              }}
              data-ga='{"category": "Endereco", "action": "Cancelar", "label": ""}'
              > Cancelar
              </a>
              }
              submit={this.onNewAddressSubmit}
            />
          )}
        </div>
      </span>
    );
  }
}
